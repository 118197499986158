import React, { Component } from 'react';
import QuestionnaireTable from './QuestionnaireTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import MultiselectTagsFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import EntitiesSelect2Component from '../EntitySelect2Component';
import FilterEntryWrapper from '../filterAndPagination/components/FilterEntryWrapper';

class QuestionnaireTableWithFilter extends Component {

    render() {
        return (
            <div>
                <FilterHandler context={this.props.context}>
                    <TextFilterEntry name="Name"  friendlyName="Search"/>
                    {/* <MultiselectTagsFilterEntry
                        name="Tags"
                        placeholder="Select Tag"
                        url="Tag/GetAll"
                    /> */}
                    <FilterEntryWrapper label="Pathway">
                        <EntitiesSelect2Component
                            name="PathwayIds"
                            placeholder="Select Pathway"
                            url="Pathway/GetSelect2Entities"
                        />
                    </FilterEntryWrapper>
                </FilterHandler>
                <QuestionnaireTable  {...this.props} />
            </div>
        );
    }
}

export default QuestionnaireTableWithFilter;