import React, { Component, useRef, useReducer, useContext } from "react";

import * as jqValidation from "jquery-validation-unobtrusive";
import * as jQuery from "jquery";

import "../css/simpleForm.css";
import AxiosHelper from "../utils/AxiosHelper";

import { SiteContext } from "../components/contexts";
import SafeSimpleEvent from "../components/SafeSimpleEvent";
import { ToArray, Take } from "../utils/Utils";
import AjaxHelper from "../utils/AjaxHelper";
import { PageStateHandler } from "../components/PageState";

function FormPanel(props) {
  return <div className="d-form-panel">{props.children}</div>;
}

function _reducer(state, { type, payload }) {
  switch (type) {
    case "SET_FORM_DATA":
      return {
        ...state,
        formValidationData: payload,
      };

    default:
      return state;
  }
}

function _Form(props) {
  const [state, dispatch] = useReducer(_reducer, {
    formValidationData: [],
    internalEvents: props.externalEvents
      ? props.externalEvents
      : SafeSimpleEvent(),
  });

  const collectDataEvent = SafeSimpleEvent();
  const validateEvent = SafeSimpleEvent();
  const clearDataEvent = SafeSimpleEvent();
  const formContext = React.createContext({ state, dispatch });
  const clearEvent = SafeSimpleEvent();

  const changeValidationsBegin = () => {
    const $ = jQuery;
    const $form = $(props.formRef.current);

    $form.unbind();
    $form.data("validator", null);
  };

  const changeValidationsEnd = () => {
    const $ = jQuery;
    const $form = $(props.formRef.current);
    $.validator.unobtrusive.parse($form);
    $form.validate();
  };

  const validationsUpdated = () => {
    changeValidationsBegin();
    changeValidationsEnd();
  };

  props.didMountEvent.subscribe(() => {
    if (props.isReadonly) {
      return;
    }

    AxiosHelper.get(`Form/GetFormValidationRules?formName=${props.name}`)
      .then((response) => {
        if (response) {
          dispatch({
            type: "SET_FORM_DATA",
            payload: response.data,
          });

          validationsUpdated();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, `_FORM_${props.id}`);

  props.willUnmountEvent.subscribe(() => {}, `_FORM_${props.id}`);
  const handleInternalEvents = ({ type, payload }) => {
    if (type === "submit") {
      onSubmit();
    }
  };
  const handleExternalEvents = ({ type, payload }) => {
    if (type === `${props.name}_collect_data`) {
      const $ = jQuery;

      let isValid = true;
      const $form = $(`#${props.id}`);
      const subscriber = validateEvent.riseEventCheckSpecificResult(
        payload,
        false
      );
      const validResult = $form.valid();
      if (!validResult || subscriber) {
        isValid = false;

        if (!props.collectIfNotValid) {
          return false;
        }
      }

      collectDataEvent.riseEvent(payload);
      return isValid;
    }
    if (type === `${props.name}_collect_data_no_validation`) {
      collectDataEvent.riseEvent(payload);
    } else if (type === `${props.name}_collect_by_name`) {
      const child = ToArray(props.children).find(
        (c) => c.props.name === payload
      );
      if (child) {
        const result = state.internalEvents.riseEventGetFirstDefinedResult({
          type: "collect_by_name",
          payload,
        });

        if (result !== undefined) {
          return result;
        }
      }

      return null;
    }
  };
  if (state.internalEvents && !props.externalEvents) {
    state.internalEvents.subscribe(handleInternalEvents, "ME");
  }

  if (props.externalEvents) {
    props.externalEvents.subscribe(handleExternalEvents, props.name);
  }

  const onSubmit = (ev) => {
    if (!props.url || props.isReadonly) {
      return;
    }

    let formData = {};
    const $ = jQuery;
    const $form = $(props.formRef.current);
    const subscriber = validateEvent.riseEventCheckSpecificResult(
      formData,
      false
    );
    const validResult = $form.valid();
    if (!validResult || subscriber) {
      return;
    }

    if (props.hiddenData) {
      Object.keys(props.hiddenData).forEach((key) => {
        formData[key] = props.hiddenData[key];
      });
    }

    collectDataEvent.riseEvent(formData);

    //#######################################
    PageStateHandler.SetLoading();
    if (props.onSubmitStart) {
      props.onSubmitStart(formData);
    }
    const containsFile = props.containsFile;
    if (!containsFile) {
      AxiosHelper.post(props.url, formData)
        .then((result) => {
          !props.dontClear && clearEvent.riseEvent();
          if (props.callback) {
            props.callback(result.data);
          }
          PageStateHandler.SetReady();
        })
        .catch((err) => {
          if (props.onError) {
            props.onError(err);
          }
          PageStateHandler.SetReady();
        });
      return;
    }

    const finalData = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] != null && formData[key] != undefined) {
        if (Array.isArray(formData[key])) {
          finalData.append(key + "[]", formData[key]);
        } else {
          finalData.append(key, formData[key]);
        }
      }
    });
    AjaxHelper.postFile(
      props.url,
      finalData,
      (result) => {
        !props.dontClear && clearDataEvent.riseEvent();
        if (props.callback) {
          props.callback(result);
        }
        PageStateHandler.SetReady();
      },
      (err) => {
        if (props.onError) {
          props.onError(err);
        }
        PageStateHandler.SetReady();
      }
    );
  };

  const render2Col = (noOfColumns = 2) => {
    const children = ToArray(props.children);

    const itemsPerColumn = Math.floor(children.length / noOfColumns);
    const restItemsCount = children.length % noOfColumns;

    const colsItems = [];

    //for (let i = 0; i < noOfColumns; i++) {
    //    colsItems.push(Take(children, itemsPerColumn, (i * itemsPerColumn)))
    //}

    colsItems.push(Take(children, itemsPerColumn + 1, 0 * itemsPerColumn));
    colsItems.push(Take(children, itemsPerColumn - 1, 1 * itemsPerColumn + 1));

    if (restItemsCount) {
      const restItems = Take(
        children,
        restItemsCount,
        children.length - restItemsCount
      );
      restItems.forEach((t) => colsItems[0].push(t));
    }

    return colsItems.map((col) => (
      <div className="d-form-col">
        {col.map((entry) =>
          React.cloneElement(entry, {
            formContext,
            translateValidations: props.translateValidations,
            collectDataEvent,
            clearEvent,
            validateEvent,
            externalEvents: state.internalEvents,
            isReadonly: props.isReadonly,
          })
        )}
      </div>
    ));
  };

  const render1Col = () => {
    return ToArray(props.children).map((c) =>
      React.cloneElement(c, {
        formContext,
        translateValidations: props.translateValidations,
        collectDataEvent,
        clearEvent,
        validateEvent,
        externalEvents: state.internalEvents,
        isReadonly: props.isReadonly,
      })
    );
  };

  const renderMap = {
    ["1-col"]: render1Col,
    ["2-col"]: render2Col,
  };

  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      ref={props.formRef}
      id={props.id}
      className="d-simple-form"
      style={props.style}
      data-align={props.align ? props.align : "1-col"}
    >
      <formContext.Provider value={{ state, dispatch }}>
        {props.align && typeof renderMap[props.align] === "function"
          ? renderMap[props.align]()
          : render1Col()}
      </formContext.Provider>
      {props.url && !props.isReadonly ? (
        <FormPanel>
          <input
            type="button"
            className="secondary-button"
            value="Submit"
            onClick={onSubmit}
          />
        </FormPanel>
      ) : null}
    </form>
  );
}

class Form extends Component {
  state = {
    didMountEvent: SafeSimpleEvent(),
    willUnmountEvent: SafeSimpleEvent(),
    formRef: React.createRef(),
  };

  componentDidMount() {
    this.state.didMountEvent.riseEvent();
  }

  componentWillUnmount = () => {
    this.state.willUnmountEvent.riseEvent();
  };

  render() {
    return (
      <SiteContext.Consumer>
        {({ translateValidations }) => {
          return (
            <_Form
              {...this.props}
              formRef={this.state.formRef}
              willUnmountEvent={this.state.willUnmountEvent}
              didMountEvent={this.state.didMountEvent}
              translateValidations={translateValidations}
              externalEvent={this.props.externalEvent}
            >
              {this.props.children}
            </_Form>
          );
        }}
      </SiteContext.Consumer>
    );
  }
}

export { Form, FormPanel };
