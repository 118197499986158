import React, { Component, useContext } from "react"
import { Redirect } from 'react-router'
import LoginForm from "../components/LoginForm";
import { SiteContext } from "../components/contexts";
import { Notifications } from "../components/NotificationsComponent";
import ObjectWrapper from "../components/ObjectWrapper";
import SiteState from '../components/SiteState';

function _LoginPage(props) {
    const toastr = useContext(Notifications)

    props.toastr.setObject(toastr)

    return (null)
}

class LoginPage extends Component {
    constructor(props) {
        super(props)

        this.toastr = ObjectWrapper()
    }

    goToForgotPassword = () => {
        this.props.SetSiteState(SiteState.ForgotPassword)
    }
    render() {
        return (
            <>
                <_LoginPage toastr={this.toastr} />
                <SiteContext.Consumer>
                    {
                        (value) => (
                            value.state.isAuthenticated ?
                                (<Redirect to="/" />) :
                                (<>
                                    <div className="login-container">
                                        <div>
                                            <div className="logo-login">Login</div>
                                            <LoginForm toastr={this.toastr} onSuccess={value.OnLoginSuccess} />
                                        </div>
                                        <div className="forgot-password">
                                            <a onClick={this.goToForgotPassword}>Forgot your password?</a>
                                        </div>
                                    </div>
                                </>
                                )
                        )
                    }
                </SiteContext.Consumer>
            </>
        )
    }
}

export default LoginPage