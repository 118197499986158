import React, { Component } from 'react'
import { SiteContext } from './contexts';

import "../css/themeSwitch.css"

export class ThemeSwitch extends Component {
  render() {
    return (
      <SiteContext.Consumer>
        {
          ({ state, translate, dispatch }) => (
            <div className="d-theme-switch">
              <select defaultValue={state.Theme} onChange={
                (ev) => dispatch({ type: "SET_THEME", payload: ev.target.value })
              }>
                {
                  Object.keys(state.Themes).map((k) =>
                    <option key={k} value={k}>{state.Themes[k].name}</option>
                  )
                }
              </select>
            </div>
          )
        }
      </SiteContext.Consumer>
    )
  }
}



export default ThemeSwitch
