import React, { Component } from 'react'
import SafeSimpleEvent from '../SafeSimpleEvent';
import { Form } from "../../form/Form"
import FormEntry from '../../form/FormEntry';
import { ValidResult } from '../../utils/Utils';
import InputComponent from '../InputComponent';

export class SaveTagDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
            isEdit: false,
            internalEvents: SafeSimpleEvent(),

            data: {}
        }

        this.state.internalEvents.subscribe(this.handleEevent, "ME")

        if (this.props.eventProxy) {
            this.props.eventProxy.setEvent(this.state.internalEvents)
        }
    }

    clearAndClose = () => {
        this.setState({
            isVisible: false,
            isEdit: false,
            data: {}
        })
    }

    handleEevent = ({ type, payload }) => {
        if (type === "show_tag_dialog") {
            this.setState({ isVisible: true, isEdit: false })
        }
        else if (type === "show_tag_dialog_edit") {
            this.setState({
                isVisible: true,
                isEdit: true,
                data: {
                    ...payload
                }
            })
        }
        else if (type === "hide_tag_dialog") {
            this.clearAndClose()
        }
    }

    hide = (payload) => {
        this.state.internalEvents.riseEvent({ type: "hide_tag_dialog", payload: null })
    }

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess(`Successfully created tag "${result.Data.Name}"`)
            
            this.state.internalEvents.riseEvent({
                type:"save_tag_success",
                payload: result.Data
            })

            this.hide(result)
        }
        else {
            this.props.toastr.ShowError(`Failed to create the tag`)
        }
    }

    onError = (err) => {
        this.props.toastr.ShowError(`An error occurred, please try again`)
    }

    render() {
        const hiddenData = {}

        if (this.state.isEdit) {
            hiddenData["Id"] = this.state.data.Id
        }

        return this.state.isVisible ? (
            <div className="d-dialog-overlay">
                <div className="d-dialog">
                    <div className="d-dialog-header">
                        <label>{`${this.state.isEdit ? "Edit" : "Create"} Tag`}</label>
                        <span className="d-dialog-close" title="Close" onClick={() => {
                            this.hide()
                        }}>X</span>
                    </div>
                    <div className="d-dialog-body">
                        <Form
                            name="TagForm"
                            url="Tag/Save"
                            hiddenData={hiddenData}
                            dontClear={true}
                            onError={this.onError}
                            callback={this.onSaveCallback}>
                            <FormEntry text="Name" name="Name" value={this.state.data.Name}>
                                <InputComponent />
                            </FormEntry>
                        </Form>
                    </div>
                    {/* <div className="d-dialog-footer">

                    </div> */}
                </div>
            </div>
        ) : null
    }
}

export default SaveTagDialog
