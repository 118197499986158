import React, { Component, useContext, useReducer } from 'react';

import SaveInformationCardDialogComponent from '../components/SaveInformationCardDialogComponent';
import InformationCardTable from '../components/table/InformationCardTable';
import QueryString from 'query-string';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import AxiosHelper from '../utils/AxiosHelper';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import PrimaryButton from '../components/buttons/PrimaryButton';
import EventProxy from "../components/EventProxy"
import { Notifications } from '../components/NotificationsComponent';
import { PageStateHandler } from '../components/PageState';
import { Tooltip } from 'antd';
import SavePathwayDialog from '../components/SavePathwayDialog';
import CreateQuestionnaireInfoCardDialog from '../components/CreateQuestionnaireInfoCardDialog';
import { Link } from "react-router-dom"

import "../css/pathwayDetails.css"

const _reducer = function (state, { type, payload }) {
    switch (type) {
        case "SET_DATA":
            const { data, pathwayId } = payload
            return {
                ...state,
                data,
                pathwayId
            }
        default:
            return state
    }
}
function _PathwayDetails(props) {
    const queryParams = QueryString.parse(props.location.search)
    const [state, dispatch] = useReducer(_reducer, {
        pathwayId: queryParams.pathwayId,
        isTemplate: queryParams.isTemplate == 'true',
        data: null,

        tableEvents: SafeSimpleEvent(),

        savePathwayProxy: EventProxy(),
        saveQuestionnaireInfoCardProxy: EventProxy(),
        saveInfoCardProxy: EventProxy()
    })

    const toastr = useContext(Notifications)

    const SetData = (data, pathwayId, currentState) => {
        dispatch({
            type: "SET_DATA",
            payload: {
                data,
                pathwayId,
                currentState
            }
        })
    }
    const LoadPathway = (id) => {
        if (!id) {
            id = state.pathwayId
        }

        PageStateHandler.SetLoading()

        AxiosHelper.post(
            `Pathway/GetPathwayData?id=${id}`).then(
                (response) => {
                    if (response && response.data.Success) {
                        SetData(response.data.Data, id)
                        PageStateHandler.SetReady()
                    }
                    else {
                        PageStateHandler.SetTo("ERROR")
                    }
                }).catch(
                    (error) => {
                        PageStateHandler.SetTo("ERROR")
                    })
    }

    props.didMountEvent.subscribe(() => {
        LoadPathway(queryParams.pathwayId)
    })
    return (
        <>
            <PageHeading>
                <label><Link to="/pathways">Back</Link></label>
                <PageTitle text={state.data ? (state.data.Name) : (null)} />
                { !state.isTemplate ?
                    <Tooltip title="Edit">
                        <label className="edit-button" style={{
                            ["display"]: "inline-block",
                            ["marginTop"]: "8px",
                            ["marginLeft"]: "15px"
                        }}
                            onClick={() => {
                                state.savePathwayProxy.riseEvent({ type: "edit", payload: state.data })
                            }}></label>
                    </Tooltip>
                    :
                    <></>
                }
                {
                    !state.isTemplate ?
                        <ActionGroup>
                            <PrimaryButton type="button" value="Add Questionnaire" click={() => {
                                state.saveQuestionnaireInfoCardProxy.riseEvent({ type: "show_dialog" })
                            }} />
                            <PrimaryButton type="button" value="New Info Card" click={() => {
                                state.saveInfoCardProxy.riseEvent({ type: "show_dialog" })
                            }} />
                        </ActionGroup>
                        : <></>
                }
            </PageHeading>

            <InformationCardTable
                isReadonly={state.isTemplate}
                toastr={toastr}
                pathwayId={state.pathwayId}
                saveQuestionnaireInfoCardProxy={state.saveQuestionnaireInfoCardProxy}
                savePathwayProxy={state.savePathwayProxy}
                saveInfoCardProxy={state.saveInfoCardProxy} />

            <SaveInformationCardDialogComponent
                isReadonly={state.isTemplate}
                toastr={toastr}
                pageState={PageStateHandler}
                eventProxy={state.saveInfoCardProxy}
                pathwayId={state.pathwayId}
            />

            <SavePathwayDialog
                isReadonly={state.isTemplate}
                pathwayId={state.pathwayId}
                isEdit={true}
                savePathwayProxy={state.savePathwayProxy}
                onClose={() => {
                    LoadPathway()
                }} />

            <CreateQuestionnaireInfoCardDialog
                isReadonly={state.isTemplate}
                pathwayId={state.pathwayId}
                toastr={toastr}
                eventProxy={state.saveQuestionnaireInfoCardProxy}
            />
        </>
    )
}

class PathwayDetails extends Component {
    constructor(props) {
        super(props)

        this.didMountEvent = SafeSimpleEvent()

    }

    componentDidMount = () => {
        this.didMountEvent.riseEvent()
    }

    render() {
        return (<_PathwayDetails {...this.props} didMountEvent={this.didMountEvent} />);
    }
}

export default PathwayDetails;
