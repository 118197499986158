import React, { Component, useContext, useReducer } from "react";
import AxiosHelper from "../utils/AxiosHelper";
import SiteState from "../components/SiteState";
import { Notifications } from "../components/NotificationsComponent";
import { PageStateHandler } from "../components/PageState";

import PaginationHandler from "../components/filterAndPagination/PaginationHandler";
import FilterHandler from "../components/filterAndPagination/FilterHandler";
import TextFilterEntry from "../components/filterAndPagination/components/TextFilterEntry";

// import "../css/chooseHospital.css"

const ChooseHospitalTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);

const _reducer = function (state, action) {
  switch (action.type) {
    case "SET_DATA": {
      return {
        ...state,
        hospitals: action.payload.hospitals,
        screenIsReady: true,
      };
    }
  }
};

function _ChooseHospitalsPage(props) {
  const [state, dispatch] = useReducer(_reducer, {
    hospitals: props.userData.hospitals ? props.userData.hospitals : [],
    screenIsReady: true,
  });

  if (state.hospitals.length == 0) {
    //getHospitals();
  }

  const hiddenData = {
    SurgeonId: props.userData.surgeonId ? props.userData.surgeonId : "",
  };

  const toastr = useContext(Notifications);

  const setHospital = (hospitalId) => {
    PageStateHandler.SetLoading();
    AxiosHelper.post("/Account/SetHospital?hospitalId=" + hospitalId)
      .then((response) => {
        debugger;
        console.log(props);
        props.dispatch({
          type: "SET_AUTH_STATE",
          payload: {
            siteState: SiteState.AfterChooseHospital,
            screenIsReady: true,
            showSwapHospital: props.showSwapSurgeon
              ? false
              : props.showSwapHospital,
            showSwapSurgeon: props.showSwapSurgeon,
            userData: {
              ...props.userData,
              hospitalId: hospitalId,
              surgeonId: 0,
            },
          },
        });
        toastr.ShowSuccess("All data will be saved on the choosen hospital");
        PageStateHandler.SetReady();
      })
      .catch((error) => {
        PageStateHandler.SetTo("ERROR");
      });
  };

  function getHospitals() {
    PageStateHandler.SetLoading();
    AxiosHelper.post("/Hospital/GetHospitalsPage", {
      ActiveIndex: 0,
    })
      .then((response) => {
        dispatch({
          type: "SET_DATA",
          payload: {
            hospitals: response.data.Data.Data,
          },
        });
        PageStateHandler.SetReady();
      })
      .catch((error) => {
        PageStateHandler.SetTo("ERROR");
      });
  }

  const renderHospitalsTable = (items) => {
    return (
      <>
        {/* <div className="s-hospitals-container-left">
                    <span>&lt;</span>
                </div> */}
        <div className="s-hospitals">
          {items.map((hospital) => {
            return (
              <div
                className="s-hospital-item"
                onClick={() => {
                  setHospital(hospital.Id);
                }}
                key={hospital.Id}
                id={hospital.Id}
              >
                <label>{hospital.Name}</label>
                <label>{hospital.Email}</label>
                <label>{hospital.Phone}</label>
                <label>{hospital.Address}</label>
              </div>
            );
          })}
        </div>
        {/* <div className="s-hospitals-container-right">
                    <span>&gt;</span>
                </div> */}
      </>
    );
  };

  return state.screenIsReady ? (
    <>
      <div className="s-hospitals-head"></div>
      <div className="s-hospitals-container">
        <FilterHandler context={ChooseHospitalTableContext}>
          <TextFilterEntry name="Name" friendlyName="Name" />
        </FilterHandler>
        <PaginationHandler
          takeCount={50}
          showPanelIfNeeded={true}
          context={ChooseHospitalTableContext}
          id="faq-pagination"
          url="/Hospital/GetHospitalsPage"
          processor={renderHospitalsTable}
          hiddenData={hiddenData}
        />
      </div>
    </>
  ) : null;
}

class ChooseHospitalsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href="/themes/default/chooseHospital.css"
        />
        {<_ChooseHospitalsPage {...this.props} />}
      </>
    );
  }
}

export default ChooseHospitalsPage;
