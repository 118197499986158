import React, { Component, useReducer, useContext } from 'react'
import { isNumber } from 'util';

function _reducer(state, action) {
    if (action.type === "UPDATE") {
        return {
            ...state,
            page: action.page,
            pageCount: action.pageCount,
            takeCount: action.takeCount,
        }
    }

    return state
}

function PaginationButton(props) {
    const onClick = (ev) => {
        props.loadpage(props.page)
    }

    const _props = { ...props, loadpage: null }
    return (
        <div {..._props} onClick={onClick} className="d-pagination-panel-btn" >
            <label>{props.text}</label>
        </div >
    )
}

export class PaginationPanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            page: props.page,
            pageCount: props.pageCount,
            takeCount: props.takeCount,
            gotoPage: props.page + 1
        }

        this.takeCountVector = [1, 5, 10, 20, 50, 100]

    }

    loadNextPage = () => {
        if (this.state.page + 1 >= this.state.pageCount) {
            return
        }

        this.props.loadPage(this.state.page + 1)
    }

    loadPrevPage = () => {
        if (this.state.page == 0) {
            return
        }

        this.props.loadPage(this.state.page - 1)
    }

    loadFirstPage = () => {
        this.props.loadPage(0)
    }

    loadLastPage = () => {
        this.props.loadPage(this.state.pageCount - 1)
    }

    takeCountChange = (ev) => {
        this.setState({ takeCount: parseInt(ev.target.value) })

        this.props.loadPage(this.state.page, parseInt(ev.target.value))
    }

    componentWillReceiveProps({ page, pageCount, takeCount }) {
        this.setState({ ...this.state, page, pageCount, takeCount })
    }

    loadPage = (ev) => {
        if (ev.key === 'Enter') {
            this.props.loadPage(this.state.gotoPage - 1)
        }
    }

    render() {
        let outElements = []

        for (let i = this.state.page - 2; i <= this.state.page - 1; i++) {
            if (i < 0) {
                continue
            }

            outElements.push(
                <PaginationButton loadpage={this.props.loadPage} page={i} text={i + 1} key={i} />
            )
        }

        outElements.push(
            <PaginationButton
                loadpage={this.props.loadPage}
                page={this.state.page}
                text={this.state.page + 1}
                isactive="True"
                key={this.state.page + 1} />
        )

        for (let i = this.state.page + 1; i <= this.state.page + 2; i++) {
            if (i >= this.state.pageCount) {
                break
            }

            outElements.push(
                <PaginationButton loadpage={this.props.loadPage} page={i} text={i + 1} key={i + 1} />
            )
        }

        return (
            <>
                <label onClick={this.loadFirstPage}>First</label>
                <label onClick={this.loadPrevPage}>Previous</label>
                <div>
                    {outElements}
                </div>
                <label onClick={this.loadNextPage}>Next</label>
                <label onClick={this.loadLastPage}>Last</label>
                <label>{this.state.currentPage}</label>

                <div className="d-pagination-group">
                    <label > Page Size </label>
                    <select onChange={this.takeCountChange} defaultValue={this.state.takeCount}>
                        {
                            this.takeCountVector.map(c =>
                                <option key={c}>{c}</option>
                            )
                        }
                    </select>


                    <input type="text" value={this.state.gotoPage} onKeyPress={this.loadPage} onChange={(ev) => {
                        const val = parseInt(ev.target.value)
                        if (isNumber(val) && !isNaN(val)) {
                            this.setState({ gotoPage: val })
                        }
                        else {
                            this.setState({ gotoPage: "" })
                        }
                    }} />
                    <input type="button" className="small-button" value="Go" onClick={(ev) => {
                        const val = this.state.gotoPage
                        this.props.loadPage(this.state.gotoPage - 1)
                    }} />

                    <label>|</label>
                    <label>{this.state.pageCount} Pages</label>
                </div>
            </>
        )
    }
}

export default PaginationPanel
