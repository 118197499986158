import React, { Component } from 'react'
import SavePatientForm from './forms/SavePatientForm'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../components/Flyout';
import { ValidResult } from '../utils/Utils';

//const surgeryOptions = [
//    { key: '0', text: 'AM', value: 0 },
//    { key: '1', text: 'PM', value: 1 },
//]

class SavePatientDialogComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            patient: {
                id: "",
                surgeonId: "",
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                code: "",
                dateOfBirth: "",
                dateOfSurgery: "",
                surgeryTime: "",
                activePathwayId: "",
                code: "",
                tags: []
            }
        };
    };

    componentWillReceiveProps(props) {
        if (props.patient) {
            this.setState({
                patient: props.patient,
                showEditPatientModal: props.showEditPatientModal

            });
        }
    }

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess("Successfuly updated the patient")
        }
        else {
            this.props.toastr.ShowError("Failed to updated the patient")
        }

        this.props.handleClose();
    }

    onError = () => {
        this.props.toastr.ShowError("An error occurred, please try again")
    }
    render() {
        var props = this.props;
        return (
            <Flyout onClose={() => { props.handleClose() }} isVisible={props.showEditPatientModal} id="edit-hospital-flout" showCloseButton={true} size="small">
                <FlyoutHead><h2 className="title-flyout">Edit Patient</h2></FlyoutHead>
                <FlyoutBody>
                    <SavePatientForm
                        patient={this.state.patient}
                        onError={this.onError}
                        onSaveCallback={this.onSaveCallback}
                    /></FlyoutBody>
                <FlyoutFooter> </FlyoutFooter>
            </Flyout>
        );
    }
}

export default SavePatientDialogComponent