import React, { Component } from "react";

import { SiteContext } from "../components/contexts";

import TranslationSwitch from "../components/TranslationSwitch";

import "../css/header.css";

import ThemeSwitch from "../components/ThemeSwitch";
import SiteState from "../components/SiteState";

import UserMenu, { UserMenuItem } from "../components/UserMenu";
import { SITE_EVENTS } from "../App";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profilePictureUrl: null,
    };
  }

  LogOut = (event) => {
    this.props.LogOutAction({ reasone: "None" });
  };

  handleClick = (dispatch, showSwapSurgeon) => {
    dispatch({
      type: "SET_SITE_STATE",
      payload: {
        siteState: showSwapSurgeon
          ? SiteState.ChooseSurgeon
          : SiteState.ChooseHospital,
      },
    });
  };

  render() {
    let isAuth = this.props.isAuthenticated;

    const profilePictureUrl = this.state.profilePictureUrl;

    return (
      <div className="site-header">
        <SiteContext.Consumer>
          {({ dispatch, state, translate, LogOut }) => (
            <>
              {/* <TranslationSwitch /> */}
              {/* <ThemeSwitch /> */}
              {
                (state.siteState = SiteState.LoggedIn ? (
                  <>
                    <div className="user-panel">
                      <label>{`${state.userData.email}`}</label>
                      <UserMenu imgUrl={state.userData.profilePictureUrl}>
                        <UserMenuItem
                          text="My Profile"
                          onClick={() => {
                            if (this.props.routerEvents) {
                              this.props.routerEvents.riseEvent({
                                type: "push",
                                payload: "/myprofile",
                              });
                            }
                          }}
                        />
                        {state.showSwapHospital ? (
                          <UserMenuItem
                            text="Swap Hospitals"
                            onClick={() => {
                              this.handleClick(dispatch, false);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {state.showSwapSurgeon ? (
                          <UserMenuItem
                            text="Swap Surgeons"
                            onClick={() => {
                              this.handleClick(dispatch, state.showSwapSurgeon);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        <UserMenuItem
                          text="Logout"
                          onClick={() => {
                            LogOut();
                          }}
                        />
                      </UserMenu>
                    </div>
                  </>
                ) : (
                  <div>
                    <label>Not authed</label>
                  </div>
                ))
              }
            </>
          )}
        </SiteContext.Consumer>
      </div>
    );
  }
}

export default Header;
