import React from "react";
import InputComponent from "./InputComponent";
import EntitiesSelect2Component from "./EntitySelect2Component";
import FormEntry from "../form/FormEntry";
import { Form } from "../form/Form";
import GalleryItemPickerComponent from "./GalleryItemPickerComponent";
import Flyout, {
  FlyoutHead,
  FlyoutBody,
  FlyoutFooter,
} from "../components/Flyout";
import SafeSimpleEvent from "./SafeSimpleEvent";
import { ValidResult, ToArray } from "../utils/Utils";
import EntitiesSelectComponent from "../components/EntitySelectComponent";
import { GalleryItemType } from "../utils/Utils";
import { DialogManager } from "./DialogManager";
import GeneralInformationResources from "../components/GeneralInformationResources";

class SaveFaqItemDialogComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      coverUrl: "",
      coverFile: "",
      title: "",
      description: "",
      parentId: "",
      tags: [],
      showCreateModal: false,
      resourcesUrl: [],

      events: SafeSimpleEvent(),
      isEdit: false,
    };

    if (this.props.events) {
      this.props.events.setEvent(this.state.events);
    }

    this.state.events.subscribe(this.handleEvent, "SAVE_FAQ_DIALOG");
  }

  handleEvent = ({ type, payload }) => {
    if (type === "edit") {
      this.onEdit(payload);
    } else if (type === "open") {
      this.handleOpen(payload);
    } else if (type === "close") {
      this.handleClose(payload);
    }
  };

  onEdit = ({ menuItemId, obj }) => {
    const tags = obj.TagIds;

    this.setState({
      title: obj.Title,
      coverUrl: obj.CoverUrl,
      description: obj.Description,
      id: obj.Id,
      parentId: obj.ParentId,
      tags,
      showCreateModal: true,
      resources: obj.GeneralResources,
      isEdit: true,
    });
  };

  onSaveCallback = (result) => {
    if (ValidResult(result)) {
      this.props.toastr.ShowSuccess(
        "Successfully created the general information entry"
      );
      this.handleClose();
    } else {
      this.props.toastr.ShowError("Failed to create general information entry");
    }
  };

  handleClose = () => {
    this.setState({ showCreateModal: false });
    this.props.onClose();
  };

  handleOpen = () => {
    this.clearState(true);
  };

  onError = (err) => {
    this.props.toastr.ShowError("An error occurred, please try again");
  };

  clearState = (show = false, isEdit = false) => {
    this.setState({
      id: "",
      coverUrl: "",
      coverFile: "",
      title: "",
      description: "",
      parentId: "",
      tags: [],
      resources: [],
      showCreateModal: show,
      isEdit,

      formEvents: SafeSimpleEvent(),
    });
  };

  render() {
    var state = this.state;

    const hidden = {};
    if (this.state.isEdit) {
      hidden["Id"] = this.state.id;
    }
    return (
      <>
        <Flyout
          onClose={() => {
            this.handleClose();
          }}
          isVisible={state.showCreateModal}
          id="edit-hospital-flout"
          showCloseButton={true}
          size="small"
        >
          <FlyoutHead>
            <h2 className="title-flyout">{`${
              this.state.isEdit ? "Edit" : "Create"
            } General Information`}</h2>
          </FlyoutHead>
          <FlyoutBody>
            <Form
              name="GeneralInformationWebModel"
              url="GeneralInformation/Save"
              containsFile={true}
              callback={this.onSaveCallback}
              externalEvents={this.state.events}
              onError={this.onError}
              dontClear={false}
              align="1-col"
              hiddenData={hidden}
            >
              <FormEntry name="Cover" text="Cover" value={this.state.coverUrl}>
                <GalleryItemPickerComponent
                  dialogTitle="Pick an image"
                  type={GalleryItemType.Image}
                />
              </FormEntry>
              <FormEntry name="Title" text="Title*" value={this.state.title}>
                <InputComponent />
              </FormEntry>
              <FormEntry
                name="Description"
                text="Description*"
                value={this.state.description}
              >
                <InputComponent />
              </FormEntry>
              <FormEntry
                name="TagIdsStr"
                action={
                  <span
                    onClick={() => {
                      DialogManager.Show("tag_dialog", ({ type, payload }) => {
                        if (type === "save_tag_success") {
                          this.state.events.riseEvent({
                            type: "TagIdsStr_add_option",
                            payload: {
                              key: payload.Id,
                              text: payload.Name,
                              value: payload.Id,
                            },
                          });
                        }
                      });
                    }}
                  >
                    +Create Tag
                  </span>
                }
                text="Tags*"
                value={this.state.tags}
              >
                <EntitiesSelect2Component
                  placeholder="Select Tags"
                  url="Tag/GetAll"
                />
              </FormEntry>
              <FormEntry
                name="ParentId"
                text="Parent"
                value={this.state.parentId}
              >
                <EntitiesSelectComponent
                  placeholder="Choose parent"
                  url={"GeneralInformation/GetAll?excludeId=" + this.state.id}
                  entityKey="Title"
                />
              </FormEntry>
              <FormEntry
                value={this.state.resources}
                name="GeneralResourcesString"
              >
                <GeneralInformationResources />
              </FormEntry>
            </Form>
          </FlyoutBody>
          <FlyoutFooter> </FlyoutFooter>
        </Flyout>
      </>
    );
  }
}

export default SaveFaqItemDialogComponent;
