import React, { Component } from 'react'

import { SITE_EVENTS } from '../App';

import uuid from "uuid"

export class UserMenuItem extends Component {
    onClickHandler = () => {
        this.props.onClick()
        this.props.onClickHandler()
    }

    render() {
        return (
            <div className="d-user-menu-item" onClick={this.onClickHandler}>
                <label>{this.props.text}</label>
            </div>
        )
    }
}

export class UserMenu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
            imgUrl: this.props.imgUrl,
            timestamp: (new Date()).getTime()
        }
    }

    componentWillMount = () => {
        SITE_EVENTS.subscribe(this.handleSiteEvents, "USER_MENU")
        document.addEventListener("mousedown", this.handleDocumentClick, false)
    }

    componentWillUnmount = () => {
        SITE_EVENTS.unsubscribe("USER_MENU", true)
        document.removeEventListener("mousedown", this.handleDocumentClick, false)
    }

    handleSiteEvents = ({ type, payload }) => {
        if (type === "SET_PROFILE_PICTURE_URL") {
            if (payload != null) {
                this.state.imgUrl = payload
                this.state.timestamp = (new Date()).getTime()

                this.forceUpdate()
            }
        }
    }

    handleDocumentClick = ({ target }) => {
        if (this.state.isVisible) {
            if (!this.node.contains(target)) {
                this.setState({ isVisible: false })
                return
            }
        }
    }

    onItemClick = () => {
        this.setState(prevState => ({ isVisible: !prevState.isVisible }))
    }

    render() {
        return (
            <div className="d-user-menu" ref={node => this.node = node}>
                <label onClick={() => {
                    this.setState(prevState => ({ isVisible: !prevState.isVisible }))
                }} >
                    <img src={
                        `${this.state.imgUrl}?timestamp=${this.state.timestamp}`
                    } />
                </label>
                {
                    this.state.isVisible ? (
                        <div className="d-user-menu-container">
                            {React.Children.map(this.props.children, (c) => React.cloneElement(c, { onClickHandler: this.onItemClick }))}
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

export default UserMenu
