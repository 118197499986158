const jp = {
    locale: "jp",
    name: "Japan",
    General: {
        EmailLabel: "Eメール",
        PassowrdLabel: "パスワード",
        ChooseLanguageLabel: "言語を選択",
        DarkThemeLabel: "暗いテーマ",
        ContrastThemeLabel: "コントラストのテーマ"
    },
    Header: {
        NameLabel: "ヘッダ",
        WelcomeTitleLabel: "ようこそ ${email} | ${name}",
        LogOutLabel: "ログアウト"
    },
    Login: {
        ThisIsLoginPageLabel: "ログインページです"
    },
    Menu: {
        HomeLabel: "ホームページ",
        TabsExampleLabel: "タブの例",
        FilterAndPaginationExampleLabel: "フィルターとページネーション"
    },
    Form: {
        EmailLabel: "Eメール",
        FirstNameLabel: "ファーストネーム",
        LastNameLabel: "苗字",
        AddressLabel: "住所",
        Address2Label: "住所 2"
    },
    Validations: {
        ["data-val-regex"]: "フィールド ${fieldName} 有効じゃない",
        ["data-val-minlength"]: "フィールド ${fieldName} の最小長は ${min} でなければなりません",
        ["data-val-maxlength"]: "フィールド ${fieldName} の最大長は ${max} でなければなりません",
        ["data-val-required"]: "フィールド ${fieldName} は必須です",
    }
}

const ro = {
    locale: "ro",
    name: "Română",
    General: {
        EmailLabel: "E-mail",
        PassowrdLabel: "Parola",
        ChooseLanguageLabel: "Alege limba",
        DarkThemeLabel: "Tema Neagra",
        ContrastThemeLabel: "Tema Contrast"
    },
    Header: {
        NameLabel: "Antet",
        WelcomeTitleLabel: "Bine ai venit ${email} | ${name}",
        LogOutLabel: "Iesire"
    },
    Login: {
        ThisIsLoginPageLabel: "Aceasta este pagina de login"
    },
    Menu: {
        HomeLabel: "Acasa",
        TabsExampleLabel: "Exemplu taburi",
        FilterAndPaginationExampleLabel: "Exemplu filtru si paginare"
    },
    Form: {
        EmailLabel: "E-mail",
        FirstNameLabel: "Prenume",
        LastNameLabel: "Nume",
        AddressLabel: "Adresa",
        Address2Label: "Adresa 2"
    },
    Validations: {
        ["data-val-regex"]: "Campul ${fieldName} nu este valid",
        ["data-val-minlength"]: "Campul ${fieldName} trebuie sa fie cel putin ${min} caractere",
        ["data-val-maxlength"]: "Campul ${fieldName} trebuie sa fie maxim ${max} caractere",
        ["data-val-required"]: "Campul ${fieldName} este obligatoriu",
    }
}

const defaultLanguage = { name: "English(Default)", locale: "default" }

const translations = {
    active: defaultLanguage,
    all: [defaultLanguage, ro, jp]
}

export default translations