import React, { Component } from 'react'
import { Take, ToArray } from '../utils/Utils';

import "../css/listingTagsView.css"

export class ListingTagsView extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        const props = this.props
        const tags = Take(props.tags, 2)
        const leftoverCount = props.tags.length - tags.length
        const showLeftover = leftoverCount > 0

        return (
            <div className="listing-tags-container">
                {
                    tags.map(tag => { return tag ? (<div className="d-tag">{tag.Name}</div>): null })
                }
                {
                    showLeftover ?
                        (
                            <label className="leftover">
                                {"  +" + leftoverCount}
                                <div className="leftover-view">
                                    {
                                        props.tags.slice(2, props.tags.length).map(tag => { return <div className="d-tag">{tag.Name}</div> })
                                    }
                                </div>
                            </label>
                        ) : null
                }
            </div>
        )
    }
}

export default ListingTagsView
