import React, { Component } from 'react'
import SafeSimpleEvent from './SafeSimpleEvent';
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from "./Flyout"
import { Form } from '../form/Form';
import FormEntry from '../form/FormEntry';
import InputComponent from "./InputComponent"
import EntitiesSelect2Component from './EntitySelect2Component';
import { ValidResult } from '../utils/Utils';
import { PageStateHandler } from './PageState';
import AxiosHelper from '../utils/AxiosHelper';
import DialogManager from './DialogManager';
import TimePickerComponent from './TimePickerComponent';

function SimpleLink(props) {
    return (
        <a href={props.href}>{props.text}</a>
    )
}

export class CreateQuestionnaireInfoCardDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pathwayId: props.pathwayId,
            internalEvents: SafeSimpleEvent(),
            isVisible: false,
            isEdit: false,
            isReadonly: this.props.isReadonly,
            InfoCard: {
                Id: null
            }
        }

        this.state.internalEvents.subscribe(this.handleEvents, "CREATE_QUESTIONNAIRE_DIALOG")

        if (this.props.eventProxy) {
            this.props.eventProxy.setEvent(this.state.internalEvents)
        }
    }

    clearInfCardData = (visible, isEdit = false) => {
        !visible && (visible = this.state.isVisible)
        this.setState({
            InfoCard: { Id: null },
            isVisible: visible,
            isEdit,
            isReadonly: false
        })
    }

    refreshData = (id) => {
        PageStateHandler.SetLoading()

        AxiosHelper.post(
            `/InformationCard/GetInformatioCardData?id=${id}`).then(
                ({ data }) => {
                    const result = data

                    if (ValidResult(result)) {
                        this.setState({
                            InfoCard: {
                                ...result.Data
                            },
                            isVisible: true,
                            isEdit: true
                        })
                    }
                    else {
                        this.props.toastr.ShowError("Failed to get information card data")
                        this.state.internalEvents.riseEvent({ type: "hide_dialog", payload: { clear: true } })
                    }

                    PageStateHandler.SetReady()
                })
            .catch((err) => {
                this.props.toastr.ShowError("An error occurred, please try again")
                this.state.internalEvents.riseEvent({ type: "hide_dialog", payload: { clear: true } })
                PageStateHandler.SetReady()
            })
    }

    handleEvents = ({ type, payload }) => {
        if (type === "show_dialog") {
            this.clearInfCardData(true)
        }
        if (type === "show_edit_dialog") {
            this.setState({
                isReadonly: payload.isReadonly
            })
            this.refreshData(payload.Id)
        }
        else if (type === "hide_dialog") {
            if (payload) {
                const { clear } = payload
                if (clear) {
                    this.clearInfCardData(clear)
                }
                else {
                    this.setState({ isVisible: false })
                }
            }
            else {
                this.setState({ isVisible: false })
            }

            this.state.internalEvents.riseEvent({ type: "filter" })
        }
    }

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess(`Successfuly ${this.state.isEdit ? "saved" : "created"} the questionnaire info card`)

            if (!this.state.isEdit) {
                this.state.internalEvents.riseEvent({ type: "hide_dialog" })
                this.state.internalEvents.riseEvent({ type: "filter_info_cards" })
            }
            this.state.internalEvents.riseEvent({ type: "hide_dialog" })
        }
        else {
            this.props.toastr.ShowError(`Failed to ${this.state.isEdit ? "save" : "create"} the  questionnaire info card`)
        }
    }

    onError = (err) => {
        this.props.toastr.ShowError("An error occurred, please try again")
    }

    render() {
        const state = this.state
        const props = this.props

        const hiddenData = {
            PathwayId: state.pathwayId,
            Message: "Dummy"
        }

        if (state.isEdit) {
            hiddenData["Id"] = state.InfoCard.Id
        }

        return (
            <>
                <Flyout
                    isVisible={state.isVisible}
                    id="create-questionnaire-flyout"
                    showCloseButton={true}
                    size="small"
                    onClose={() => { state.internalEvents.riseEvent({ type: "hide_dialog" }) }}>
                    <FlyoutHead><h2 className="title-flyout">{`${state.isReadonly ? "" : state.isEdit ? "Edit" : "Create"} Questionnaire Info Card`}</h2></FlyoutHead>
                    <FlyoutBody>
                        <Form
                            name="QuestionnaireInfoCardUIModel"
                            hiddenData={hiddenData}
                            url="InformationCard/SaveQuestionnaireInfoCard"
                            dontClear={true}
                            callback={this.onSaveCallback}
                            onError={this.onError}
                            isReadonly={this.state.isReadonly}
                        >
                            <FormEntry text="Title*" name="Info" value={this.state.InfoCard.Info}>
                                <InputComponent />
                            </FormEntry>
                            <FormEntry text="Day*" name="Day" value={this.state.InfoCard.Day}>
                                <InputComponent />
                            </FormEntry>
                            <FormEntry text="Time *" name="Time" value={this.state.InfoCard.Time}>
                                <TimePickerComponent format="HH:mm" />
                            </FormEntry>
                            <FormEntry text="Assigned Questionnaire*" name="QuestionnaireId" value={this.state.InfoCard.QuestionnaireId}>
                                <EntitiesSelect2Component notMultiple={true} url="Questionnaire/GetQuestionnairesEntities" />
                            </FormEntry>
                            {state.isEdit ? <SimpleLink href={`/questionnaires/savequestionnaire?questionnaireId=${this.state.InfoCard.QuestionnaireId}`} text='Edit questionnaire' />: (<></>)}
                        </Form>
                    </FlyoutBody>
                </Flyout>
            </>
        )
    }
}

export default CreateQuestionnaireInfoCardDialog
