import React from 'react'
import AxiosHelper from '../utils/AxiosHelper';
import Select2Component from './Select2Component';
import { ToArray } from '../utils/Utils';

class EntitiesSelect2Component extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            placeholder: this.props.placeholder ? this.props.placeholder : '',
            url: this.props.url ? this.props.url : '',
            options: this.props.options ? this.props.options : [],
            value: this.props.value != undefined && this.props.value != null ? this.props.value : (!this.props.notMultiple ? [] : null)
        };

        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, this.props.name)
        }

        if (this.props.validateEvent) {
            this.props.validateEvent.subscribe(this.validateEntry, this.props.name)
        }

        if (this.props.clearEvent) {
            this.props.clearEvent.subscribe(this.clearData, this.props.name)
        }

        if (this.props.externalEvents) {
            this.props.externalEvents.subscribe(this.handleEvent, this.props.name)
        }
    }

    handleEvent = ({ type, payload }) => {
        if (type === `${this.props.name}_add_option`) {
            this.setState({
                options: [...this.state.options, payload],
                value: [...this.state.value, payload.value]
            })

            this.props.onChangeEx && this.props.onChangeEx(null, [...this.state.value, payload.value])
        }
        else if (type === `${this.props.name}_set_value`) {
            this.setState({
                value: payload
            })
        }
    }

    onChange = (e, { value }) => {
        if (!this.props.onChangeEx) {
            this.props.onChange ? this.props.onChange(e, { value }) : this.setState({ value });
        }
        else {
            this.props.onChangeEx(e, value)
            this.setState({ value })
        }
    };

    validateEntry = (formData) => {
        if (this.props.setValidationMessageEvent) {
            var rulesArray = ToArray(this.props.rules)
            var requiredRule = rulesArray.find((r) => r.ValidationType === "required");
            if (requiredRule) {
                if (
                    (this.props.notMultiple == true && (this.state.value == null || this.state.value == undefined )) ||
                    ((!this.props.notMultiple && (!this.state.value || !this.state.value.length)))
                ) {
                    this.props.setValidationMessageEvent.riseEvent(requiredRule.ErrorMessage)
                    return false
                }
            }
        }

        return true
    }

    collectData = (formData) => {
        formData[this.props.name] = this.state.value;
    }

    clearData = (reasone) => {
        this.setState({ value: [] })
    }

    componentWillReceiveProps = ({ value, placeholder, url }) => {
        this.setState({
            value,
            placeholder,
            url
        })
    }

    componentDidMount = () => {
        if (this.state.options != undefined && this.state.options != null && this.state.options.length > 0) {
            return;
        }

        AxiosHelper.get(this.state.url)
            .then(result => {
                let optionsForDropdown = result.data.map(entity => {
                    return {
                        key: entity.Id,
                        text: entity.Name ? entity.Name : (entity.FirstName + ' ' + entity.LastName),
                        value: entity.Id
                    }
                });
                this.setState({
                    options: optionsForDropdown,
                });
            }).catch((err) => {

            });
    }
    render = () => {
        const props = this.props
        return (
            <Select2Component
                className={this.props.className ? this.props.className : ''}
                isReadonly={this.props.isReadonly}
                onChange={this.onChange}
                placeholder={this.state.placeholder}
                options={this.state.options}
                value={this.state.value}
                multiple={!this.props.notMultiple}
                
            />
        );
    }

}

export default EntitiesSelect2Component;