import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react'
import ImageTab from './gallery/ImageTab';
import VideoTab from './gallery/VideoTab';
import { GalleryItemType } from '../utils/Utils'

import "../css/gallery.css"

class GalleryDialogComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            url: ''
        }

        if (this.props.showGalleryEvent) {
            this.props.showGalleryEvent.subscribe(this.handleShowGallery, "GALLERY_DIALOG")
        }
    }

    handleShowGallery = (val) => {
        this.setState({
            showModal: val
        })
    }

    handleClick = ev => {
        this.setState({
            showModal: false,
            url: ev.target.src ? ev.target.src : ev.target.currentSrc ? ev.target.currentSrc : null
        }, () => {
            this.props.handleClickCallBack(this.state.url);
        });
    };

    handleClose = () => {
        this.setState({
            showModal: false
        })
    }

    render() {
        const state = this.state;
        const props = this.props;
        return (
            <Modal className="d-gallery-dialog" open={this.state.showModal} centered={false} onClose={this.handleClose} closeIcon>
                <Modal.Header>{props.dialogTitle}</Modal.Header>
                <Modal.Content className="d-gallery-dialog-content">
                    {props.type == GalleryItemType.Image ?
                        <><p>Click the title to select the image</p>
                        <ImageTab handleClick={this.handleClick} /></> :
                        props.type == GalleryItemType.Video ?
                            <><p>Click the title to select the video</p>
                            <VideoTab handleClick={this.handleClick} /></> : null
                    }

                </Modal.Content>
            </Modal >
        )
    }
}

export default GalleryDialogComponent;