import React, { Component } from 'react';
import { TimePicker } from 'antd';
import moment from 'moment'

class TimePickerComponent extends Component {
    constructor(props) {
        super(props)
        var date = moment(props.value);
        this.state = {
            value: props.value ? date : ""
        }

        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, this.props.name)
        }

        if (this.props.clearEvent) {
            this.props.clearEvent.subscribe(this.clearData, this.props.name)
        }
    }

    collectData = (formData) => {
        //here we process the value so we set the final version to the form(the version of the value that is posted to the server)
        //(eg. if this is a datepicker, set the iso-string or moment or actual js date)

        formData[this.props.name] = this.state.value.format()
    }

    clearData = (reasone) => {
        this.setState({ value: null })
    }

    onChange = (date, dateStr) => {
        this.setState({ value: date });
    }

    componentWillReceiveProps = ({ value }) => {
        //var moment = moment(value);
        this.setState({ value: value })
    }

    render() {
        const state = this.state;
        //TODO: move this in a better place to avoid this for multiple calls, the validations will not change?!
        const validations = this.props.validations ? this.props.validations : {}
        return (
            <TimePicker
                value={this.state.value} 
                onChange={this.onChange}
                name={this.props.name}
                format={this.props.format}
                disabled={this.props.isReadonly}
                {...validations}
                defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
            />
        );
    }
}

export default TimePickerComponent;