
import React, { Component, useContext } from 'react'
import SaveTagDialog from './dialogs/SaveTagDialog';

import "../css/dDialog.css"
import { Notifications } from './NotificationsComponent';
import ObjectWrapper from './ObjectWrapper';
import EventProxy from './EventProxy';
import NewCategoryDialog from './dialogs/NewCategoryDialog';

export const DialogManager = {
    Show: () => { }
}

function _DialogManagerComponent(props) {
    const toastr = useContext(Notifications)

    props.toastr.setObject(toastr)

    return null
}

export class DialogManagerComponent extends Component {
    constructor(props) {
        super(props)

        DialogManager.Show = this.Show

        this.state = {
            toastr: ObjectWrapper(),
            currentDialogName: "none",
        }

        this.dialogs = {
            "none": {},
            "tag_dialog": {
                proxy: EventProxy(),
            },
            "new_category_dialog": {
                proxy: EventProxy(),
            }
        }

        this.dialogs["tag_dialog"].proxy.subscribe(this.HandleDialogEvent, "DIALOG_MANAGER")
        this.dialogs["new_category_dialog"].proxy.subscribe(this.HandleDialogEvent, "DIALOG_MANAGER")
    }

    Show = (name, handler, payload) => {
        const dialogHandle = this.dialogs[name]
        if (!dialogHandle) {
            return false
        }

        this.currentDialogName = name

        dialogHandle.proxy.subscribe(handler, "DIALOG_MANAGER_USER")

        if (!payload) {
            dialogHandle.proxy.riseEvent({ type: `show_${name}` })
        }
        else {
            dialogHandle.proxy.riseEvent({ type: `show_${name}_edit`, payload })
        }

        return true
    }

    clear = () => {
        this.dialogs[this.currentDialogName].proxy.unsubscribe("DIALOG_MANAGER_USER")
        this.currentDialogName = "none"
    }

    HandleDialogEvent = ({ type, payload }) => {
        switch (type) {
            case "hide_new_category_dialog":
            case "hide_tag_dialog": {
                this.clear()
            } break;
            default: { } break
        }
    }

    render() {
        return (
            <>
                <_DialogManagerComponent toastr={this.state.toastr} />
                <SaveTagDialog toastr={this.state.toastr} eventProxy={this.dialogs["tag_dialog"].proxy} />
                <NewCategoryDialog toastr={this.state.toastr} eventProxy={this.dialogs["new_category_dialog"].proxy} />
            </>
        )
    }
}

export default DialogManager
