import React, { Component } from 'react';
import QuestionnairesAnswersTable from './QuestionnairesAnswersTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import EntitiesSelect2Component from '../EntitySelect2Component';
import FilterEntryWrapper from '../filterAndPagination/components/FilterEntryWrapper';
import SafeSimpleEvent from "../SafeSimpleEvent"
import PrimaryButton from "../buttons/PrimaryButton"
import axios from 'axios';

class QuestionnairesAnswersTableWithFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            internalEvents: SafeSimpleEvent()
        }
    }

    downloadAllQuestionnaireAnswers = () => {
        let data = {};
        this.state.internalEvents.riseEvent({
            type: 'collect', payload: data
        })
        axios({
            baseURL: window.ENVIRONMENT_URL,
            url: '/PatientQuestionnaireAnswer/DownloadAllQuestionnaireAnswers',
            params: data,
            method: 'GET',
            responseType: 'blob', 
            withCredentials: true,
            crossDomain: true,
            headers: {
                'Accept': 'application/csv',
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'answers.csv');
            document.body.appendChild(link);
            link.click();
        });
    }

    render() {
        return (
            <div>
                <FilterHandler context={this.props.context} externalEvent={this.state.internalEvents}>
                    <TextFilterEntry name="PatientName" friendlyName="Patient Name" />
                    <FilterEntryWrapper label="Pathway">
                        <EntitiesSelect2Component
                            className="dropdown-single-select"
                            name="PathwayId"
                            placeholder="Select Pathway"
                            url="Pathway/GetSelect2Entities"
                            notMultiple={true}
                        />
                    </FilterEntryWrapper>
                    <FilterEntryWrapper label="Questionnaire">
                        <EntitiesSelect2Component
                            className="dropdown-single-select"
                            name="QuestionnaireId"
                            placeholder="Select Questionnaire"
                            url="Questionnaire/GetAllQuestionnairesEntities"
                            notMultiple={true}
                        />
                    </FilterEntryWrapper>
                </FilterHandler>
                <div className="download-all-button-container">
                    <PrimaryButton
                        click={this.downloadAllQuestionnaireAnswers}
                        value={"Download all answers"}>
                    </PrimaryButton>
                </div>
                <QuestionnairesAnswersTable  {...this.props} />
            </div>
        );
    }
}

export default QuestionnairesAnswersTableWithFilter;