import React, { useReducer, useContext, Component } from 'react'

import uuid from "uuid"

import "../css/notifications.css"

export const Notifications = React.createContext({
  ShowSuccess: null,
  ShowError: null
});

class _notification extends Component {
  state = {
    isActive: false
  }

  constructor(props) {
    super(props)

  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({
        isActive: true
      })

      const { delay, remove, notification } = this.props
      setTimeout(() => {

        remove(notification.id)

      }, delay);

    }, 100);
  }

  render() {
    const { notification, remove } = this.props
    return (
      <div className="d-notification" data-type={notification.type} data-isactive={this.state.isActive ? "True" : "False"} >
        <div></div>
        <div className="icon"></div>
        <div className="body">
          <label>{
            notification.title
          }</label>
          <div>
            <label>
              {
                notification.message
              }
            </label>
          </div>
        </div>
        <span className="close" onClick={() => {
          remove(notification.id)
        }} title="Dismiss">x</span>
      </div>
    )
  }
}

function _NotificationsComponent(props) {

  const context = useContext(Notifications)

  if (!context.ShowSuccess)
    context.ShowSuccess = props.ShowSuccess

  if (!context.ShowError)
    context.ShowError = props.ShowError

  return (<></>)
}

class NotificationsComponent extends Component {
  state = {
    notifications: [],
    idPool: 0
  }

  constructor(props) {
    super(props)

  }

  RemoveNotification = (id) => {
    console.log(`REMOVE id ${id}`)
    this.setState(prevState => ({
      notifications: prevState.notifications.filter(t => t.node.id !== id)
    }))
  }

  AddNotification = (node, delay) => {

    this.setState(prevState => ({
      notifications: [
        ...prevState.notifications,
        {
          node: {
            ...node,
            id: prevState.idPool
          },
          delay
        }
      ],
      idPool: prevState.idPool + 1
    }))
  }

  ShowSuccess = (message, title = "Success", delay = 2500) => {
    this.AddNotification({
      title,
      message: message,
      type: "SUCCESS"
    }, delay)
  }

  ShowError = (message, title = "Error", delay = 2500) => {
    this.AddNotification({
      title,
      message: message,
      type: "ERROR"
    }, delay)
  }

  render() {
    return (
      <>
        <_NotificationsComponent ShowError={this.ShowError} ShowSuccess={this.ShowSuccess} />
        {
          this.state.notifications.length ? (

            <div className="d-notifications" >
              {
                this.state.notifications.map((node) => (
                  <_notification notification={node.node} delay={node.delay} remove={this.RemoveNotification} />
                ))
              }
            </div>
          ) : null
        }
      </>
    )
  }
}

export default NotificationsComponent
