import React, { Component, useReducer, useContext } from 'react'
import uuid from "uuid"

import "../../css/basicFilterHandler.css"

import SafeSimpleEvent from "../SafeSimpleEvent"
import { ToArray } from '../../utils/Utils';

function _FilterHandler(props) {
    const [state, dispatch] = useReducer((state) => state, {
        formData: {},
    })

    props.initEvent.subscribe(() => {
        //onFilter()
    })

    const context = useContext(props.context);

    const collectEvent = SafeSimpleEvent()
    const clearEvent = SafeSimpleEvent()
    const filterEvent = SafeSimpleEvent()

    const onExternalEvent = ({ type, payload }) => {
        if (type == 'collect') {
            collectEvent.riseEvent(payload)
        }
    }

    if (props.externalEvent) {
        props.externalEvent.subscribe(onExternalEvent);
    }

    const onFilter = (data) => {
        let formData = {}
        collectEvent.riseEvent(formData)

        if (data) {    
            formData = {
                ...formData,
                ...data
            }
        }
        context.formData = formData;
        context.filterEvent.defferedRiseEvent(formData)
    }

    filterEvent.subscribe((data) => {
        onFilter(data);
        }
    )

    const onClear = () => {
        clearEvent.riseEvent()
        context.formData = {}
        context.filterEvent.defferedRiseEvent()
    }

    const listChildren = (children) => {
        if (!Array.isArray(children)) {
            children = [children];
        }
        return children.map((element, i) => (
            React.cloneElement(element, { collectEvent, clearEvent, key: uuid(), filterEvent })
        ));
    }

    const children = ToArray(props.children)
    return (
        <div className="d-filter-handler">
            {
                children.length ? (< div className="d-filter-entries">
                    {
                        listChildren(children)
                    }
                </div>) : null
            }
            <div className="d-filter-panel">
                <input type="button" className="secondary-button" value="Filter" onClick={() => { return onFilter() }} />
                <input type="button" className="tertiary-button" value="Clear" onClick={onClear} />
            </div>
        </div>
    )
}

class FilterHandler extends Component {
    state = {
        initEvent: SafeSimpleEvent(),
        main: null
    }

    componentDidMount = () => {
        setTimeout((state) => {
            state.initEvent.riseEvent()
        }, 300, this.state);
    }

    render() {
        const props = { ...this.props, initEvent: this.state.initEvent }

        return (
            <_FilterHandler {...props} />
        )
    }
}

FilterHandler.BuildContextValue = (defaultFormData) => {
    if (!defaultFormData) {
        defaultFormData = {}
    }

    return {
        formData: {
            ...defaultFormData
        },
        filterEvent: SafeSimpleEvent(),
    }
}

export default FilterHandler
