import React, { Component } from 'react';

class VideoUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: '',
            videoPreviewUrl: props.url ? props.url : "",
            ref: React.createRef(null)
        };

        if (this.props.showEvent) {
            this.props.showEvent.subscribe(
                this.onShow,
                "VOIDEO_UPLOAD"
            )
        }
    }

    onShow = (val) => {
        this.state.ref.current && (
            this.state.ref.current.click()
        )
    }

    handleVideoChange(e) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
            this.setState({
                file: file,
                videoPreviewUrl: URL.createObjectURL(file)
            },
                () => {
                    this.props.handleVideoChange(this.state.videoPreviewUrl, this.state.file);
                });
        }

        reader.readAsDataURL(file)
    }

    componentWillUpdate(props) {
        if (props.url != this.state.videoPreviewUrl) {
            this.setState({
                videoPreviewUrl: props.url
            });
        }
    }
    
    render() {
        let { videoPreviewUrl } = this.state;
        let $imagePreview = null;
        if (videoPreviewUrl) {
            $imagePreview = (<video src={videoPreviewUrl} className="d-video-preview">
                </video>);
        }
   

        return (
            <div className="picture">
                {$imagePreview}
                <div>
                    <input ref={this.state.ref} type="file" onChange={(e) => this.handleVideoChange(e)} style={{ ["display"]: "none" }}
                        accept=".mkv,.avi,.m4v"/>
                </div>
            </div>
        )
    }
}

export default VideoUpload;