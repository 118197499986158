import React, { Component, useContext, useReducer } from 'react';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import SurgeonProfileForm from '../components/SurgeonProfileForm';
import { SiteContext } from '../components/contexts';
import { Notifications } from "../components/NotificationsComponent"

import "../css/myProfile.css"

const _reducer = function (state, action) {
    switch (action.type) {
        case 'SET_DATA': {
            return {
                ...state,
                screenIsReady: true
            }
        }
        default:
            return state
    }
}
function _MyProfilePage(props) {
    const [state, dispatch] = useReducer(_reducer, {
        screenIsReady: true,
    })

    const toastr = useContext(Notifications)

    const siteContext = useContext(SiteContext)

    return (state.screenIsReady ? (
        <>
            <PageHeading >
                <PageTitle text="My Profile" />
            </PageHeading>
            <SurgeonProfileForm toastr={toastr} siteContext={siteContext} />
        </>) : null
    )
}

class MyProfilePage extends Component {
    constructor(props) {
        super(props)

        this.didMountEvent = new SafeSimpleEvent();
    };

    componentDidMount() {
        this.didMountEvent.riseEvent();
    };

    render() {
        return (<_MyProfilePage {...this.props} didMountEvent={this.didMountEvent} />)
    }
}

export default MyProfilePage;

