import React, { Component } from 'react';

class InputComponent extends Component {
    constructor(props) {
        super(props)

        this.type = "text"
        if (props.type) {
            this.type = props.type;
        }

        this.state = {
            value: (this.props.value != undefined && this.props.value != null) ? this.props.value : ""
        }

        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, this.props.name)
        }

        if (this.props.clearEvent) {
            this.props.clearEvent.subscribe(this.clearData, this.props.name)
        }

        if (this.props.externalEvents) {
            this.props.externalEvents.subscribe(this.handleEvents, this.props.name)
        }
    }

    handleEvents = ({ type, payload }) => {
        if (type === "collect_by_name") {
            if (this.props.name === payload) {
                return this.state.value
            }

            return undefined
        }
    }

    collectData = (formData) => {
        //here we process the value so we set the final version to the form(the version of the value that is posted to the server)
        //(eg. if this is a datepicker, set the iso-string or moment or actual js date)

        formData[this.props.name] = this.state.value
    }

    clearData = (reasone) => {
        this.setState({ value: "" })
    }

    onChange = ev => {
        this.setState({
            value: ev.target.value
        });
    }

    componentWillReceiveProps = ({ value }) => {
        this.setState({ value })
    }

    onKeyPress = (ev) => {
        if (this.props.handleKeyPress) {
            this.props.handleKeyPress();
        }
        if (ev.key === 'Enter') {
            this.props.externalEvents.riseEvent({ type: "submit" });
        }
    }

    render() {
        const state = this.state;
        //TODO: move this in a better place to avoid this for multiple calls, the validations will not change?!
        const validations = this.props.validations ? this.props.validations : {}

        let className = ""

        switch (this.props.type) {
            case "text":
                className = "primary-textbox"
                break;
            case "button":
                if (this.props.subType == "secondary") {
                    className = "primary-button"
                }
                else if (this.props.subType == "tertiary") {
                    className = "tertiary-button"
                }
                else if (this.props.subType == "small") {
                    className = "small-button"
                }
                else {
                    className = "primary-button"
                }
                break;
            default:
                break;
        }
        return (
            <input type={this.type}
                name={this.props.name}
                className={className ? className : this.props.className}
                value={this.state.value} onChange={this.onChange}
                onKeyPress={this.onKeyPress}
                readOnly={this.props.isReadonly}
                placeholder={this.props.placeholder}
                {...validations}
            />
        );
    }
}

export default InputComponent;