import React, { useReducer } from 'react'

function _reducer(state, { type, payload }) {
    if (type === "SET_VALIDATION_MESSAGE") {
        return {
            ...state,
            validationMessage: payload
        }
    }

    return state
}
export default function FormField(props) {
    const [state, dispatch] = useReducer(_reducer, {
        validationMessage: ""
    })

    const setValidationMessage = (val) => {
        dispatch({
            type: "SET_VALIDATION_MESSAGE",
            payload: val
        })
    }

    if (props.setValidationMessageEvent) {
        props.setValidationMessageEvent.subscribe((data) => {
            setValidationMessage(data)
        }, props.name)
    }

    return (
        <div className={"d-form-entry " + props.className}>
            <label>{props.text}</label>
            {
                props.action ? (<label className="d-form-entry-action">
                    {
                        props.action
                    }
                </label>) : null
            }
            <div>
                {props.children}
                <span className="d-field-validation-valid" data-valmsg-for={props.name} data-valmsg-replace="true">{state.validationMessage}</span>
            </div>
        </div>
    )
}
