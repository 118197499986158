import React, { Component, useContext, useReducer } from 'react';
import { GalleryGridContext } from '../components/contexts'
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import EventProxy from "../components/EventProxy"
import { Notifications } from '../components/NotificationsComponent';
import GalleryGrid from '../components/table/GalleryGrid'

const _reducer = function (state, action) {
    switch (action.type) {
        case "SET_DATA": {
            return {
                ...state,
                patients: action.payload.data,
                screenIsReady: true
            }
        }
    }
}

function _GalleryPage(props) {
    const [state, dispatch] = useReducer(_reducer, {
        patients: [],
        screenIsReady: true,
        setStateEventWrapper: EventProxy()
    })

    const toastr = useContext(Notifications)

    const patientTableContext = useContext(GalleryGridContext);

    return (state.screenIsReady ? (
        <>
            <PageHeading >
                <PageTitle text="Gallery" />
            </PageHeading>
            <GalleryGrid />
        </>

    ) : null)
}

class GalleryPage extends Component {

    constructor(props) {
        super(props)

        this.didMountEvent = new SafeSimpleEvent();
    };

    componentDidMount() {
        this.didMountEvent.riseEvent();
    }

    render() {
        return (<_GalleryPage  {...this.props} didMountEvent={this.didMountEvent} />)
    }
}

export default GalleryPage;