import React, { Component } from 'react'

export class LoadingPage extends Component {
  render() {
    return (
      <div className="d-loading-page">
        <center><label>...Loading...</label></center>
      </div>
    )
  }
}

export default LoadingPage
