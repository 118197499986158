import React, { Component } from 'react'

export class FilterEntryWrapper extends Component {
    render() {
        return (
            <div className="d-filter-entry d-text-filter-entry">
                <label>{this.props.label}</label>
                {
                    React.cloneElement(this.props.children,
                        { clearEvent: this.props.clearEvent, collectEvent: this.props.collectEvent, collectDataEvent: this.props.collectEvent })
                }
            </div>
        )
    }

}

export default FilterEntryWrapper
