import React, { Component, useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SiteContext } from "./components/contexts";
import PatientsPage from "./pages/PatientsPage";
import PathwaysPage from "./pages/PathwaysPage";
import QuestionnaireDetails from "./pages/QuestionnaireDetails";
import GeneralInformationPage from "./pages/GeneralInformationPage";
import PathwayDetails from "./pages/PathwayDetails";
import QuestionnairesPage from "./pages/QuestionnairesPage";
import QuestionnairesAnswerPage from "./pages/QuestionnairesAnswerPage";
import MyProfilePage from "./pages/MyProfilePage";
import GalleryPage from "./pages/GalleryPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import PageNotFound from "./pages/PageNotFound";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import MenuItem from "./components/menu/MenuItem";
import Menu from "./components/menu/Menu";

const _Root = (props) => {
  return (
    <>
      <Header routerEvents={props.routerEvents} />
      <div className="site-container">
        <Router>
          <div className="site-body">
            <div className="site-left-side">
              <Menu routerEvents={props.routerEvents}>
                <MenuItem
                  Id="logo-menu-item"
                  text="SuXeed"
                  to="/"
                  isLogo={true}
                />
                <MenuItem
                  Id="patient-menu-item"
                  text="Patients"
                  to="/patients"
                />
                <MenuItem
                  Id="pathways-menu-item"
                  text="Pathways"
                  to="/pathways"
                  includedLocaitons={["pathwaydetails"]}
                />
                <MenuItem
                  Id="questionnaires-menu-item"
                  text="Questionnaires"
                  to="/questionnaires"
                  includedLocaitons={["savequestionnaire"]}
                />
                <MenuItem
                  Id="information-menu-item"
                  text="Resources"
                  to="/information"
                />
                <MenuItem Id="gallery-menu-item" text="Gallery" to="/gallery" />
                <MenuItem
                  Id="questionnaires-answers-menu-item"
                  text="Answers"
                  to="/questionnaire-answers"
                />
              </Menu>
            </div>
            <div className="site-right-side">
              <div className="d-site-wrapper">
                <Switch>
                  <Route path="/" exact component={PatientsPage} />
                  <Route path="/patients" exact component={PatientsPage} />
                  <Route path="/pathways" exact component={PathwaysPage} />
                  <Route
                    path="/pathways/pathwaydetails"
                    component={PathwayDetails}
                  />
                  <Route
                    path="/questionnaires"
                    exact
                    component={QuestionnairesPage}
                  />
                  <Route
                    path="/questionnaires/savequestionnaire"
                    component={QuestionnaireDetails}
                  />
                  <Route
                    path="/information"
                    exact
                    component={GeneralInformationPage}
                  />
                  <Route path="/myprofile" exact component={MyProfilePage} />
                  <Route path="/gallery" exact component={GalleryPage} />
                  <Route
                    path="/questionnaire-answers"
                    exact
                    component={QuestionnairesAnswerPage}
                  />
                  <Route component={PageNotFound} />
                </Switch>
              </div>
            </div>
          </div>
        </Router>
        <Footer />
      </div>
    </>
  );
};

class Root extends Component {
  render() {
    const routerEvents = this.props.routerEvents;
    return (
      <>
        <SiteContext.Consumer>
          {({}) => <_Root routerEvents={routerEvents} />}
        </SiteContext.Consumer>
      </>
    );
  }
}

export default Root;
