import React, { Component, useContext } from 'react'

import "../css/base/cssReset.css";
import "../css/base/basicStructure.css";
import "../css/fonts.css"
import FormEntry from '../form/FormEntry';
import InputComponent from '../components/InputComponent';
import { Form } from '../form/Form';
import { ValidResult, ExtractParamsFromQueryString } from '../utils/Utils';
import queryString from "query-string"
import AxiosHelper from '../utils/AxiosHelper';
import SiteState from '../components/SiteState';


class ResetPasswordPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageState: "SUCCESS",
            params: '',
            renderers: {
                ["SUCCESS"]: this.renderSuccess,
                ["FAIL"]: this.renderFail,
                ["INVALID"]: this.renderInvalidData
            }
        }
    }

    componentDidMount = () => {

        const params = ExtractParamsFromQueryString(window.location.href);
        if (!params.code || params.code.trim() === "") {
            this.setState({ pageState: "INVALID" })
            return;
        }
        this.setState({ params});
        AxiosHelper.post(
            `Account/RessetPassword?code=${params.code}`).then(
            (result) => {
                if (ValidResult(result.data)) {
                    this.setState({ pageState: "SUCCESS" })
                }
                else {
                    this.props.toastr.ShowError("Failed to set your password")
                    this.setState({ pageState: "FAIL" })
                }
            }).catch(
            (err) => {
                this.props.toastr.ShowError("An error occurred, please try again")
                this.setState({ pageState: "FAIL" })
            });
    }

        onRessetPassword = (result) => {
            if (result.Success == true) {
          
                this.props.toastr.ShowSuccess("Your password was set succesfully")
                window.history.pushState("", "", '/');
                this.props.SetSiteState(SiteState.LoggedOut)        
            }
            else {
                this.props.toastr.ShowError("Resset password failed")
            }

        }

        onError = (result) => {
            this.props.toastr.ShowError("An error occurred, please try again")

        }

    renderSuccess = () => {
        return (
            <>
                <div className="logo-resset-password">Resset Password</div>
                <Form name="RessetPassword"
                    url="Account/ResetPassword"
                    align="1-col"
                    dontClear={true}
                    containsFile={false}
                    callback={this.onRessetPassword}
                    onError={this.onError}
                    hiddenData={{ Code: this.state.params.code }}
                >
                    <FormEntry name="Password" text="Password">
                        <InputComponent type="password" />
                    </FormEntry>
                    <FormEntry name="ConfirmPassword" text="ConfirmPassword">
                        <InputComponent type="password" />
                    </FormEntry>
                </Form>
                    <div>Your password must have a minimum of 6 characters consisting of at least 1 upper and lower case, numerical and a special character [!,@,#,$,%,&]</div>
            </>
        )
    }

    renderFail = () => {
        return (
            <h1>Failed to set  your password.</h1>
        )
    }

        renderInvalidData = () => {
            return (
                <h1>Invalid data.</h1>
            )
    }

        render() {

            return (
                <div className="reset-password-container">
                    {
                        this.state.renderers[this.state.pageState]()
                    }
                </div >
            )
    }
    }

    export default ResetPasswordPage
