import React from "react";
import InputComponent from "./InputComponent";
import GalleryItemPickerComponent from "./GalleryItemPickerComponent";
import { Form } from "../form/Form";
import FormEntry from "../form/FormEntry";
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from "./Flyout";
import TextAreaComponent from "./TextAreaComponent";
import { ValidResult, StrDateToMoment } from "../utils/Utils";
import SafeSimpleEvent from "./SafeSimpleEvent";
import AxiosHelper from "../utils/AxiosHelper";
import { GalleryItemType } from "../utils/Utils";
import { PageStateHandler } from "./PageState";
import TimePickerComponent from "./TimePickerComponent";

class SaveInformationCardDialogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      coverUrl: "",
      videoCoverUrl: "",
      externalVideoCoverUrl: "",
      coverFile: "",
      videoCoverFile: "",
      externalVideoFile: "",
      days: "",
      info: "",
      time: null,
      message: "",
      videoUrl: "",
      externalUrl: "",
      externalResourceName: "",
      isReadonly: this.props.isReadonly,

      showFlyout: false,
      isEdit: false,
      isReadonly: false,

      /*
                -this is the EventObject that handles the internal events like, 
                    show, close, etc (you define them) 
                 -this is where you handle the actual events   
            */
      internalEventHandler: SafeSimpleEvent(),
    };

    /*
            by passing an EventProxy object to this component, you will be able to :
                listen to internal purposed events but not only.
                When you rise an event, all subscibers, are notified.
                In this manner, a subscriber gets notified with the event type and the payload (if any), 
                * Important * all rised event types are dispatched to all subscribers. The subscriber decides what type of event it wants to handle.
        */
    if (props.eventProxy) {
      props.eventProxy.setEvent(this.state.internalEventHandler);
    }

    this.state.internalEventHandler.subscribe(
      this.handleEvent,
      "SAVE_INFO_CARD_DIALOG"
    );

    /*
             ! SafeSimpleEvent and EventProxy usage example !
        
                EventProxy desc:
                    This is a proxy for a SafeSimpleEvent.
                    Intially the wrapped event is "null", this means that someone must latter set the wrapped event
                    This allows us to subscribe to the wrapped event before and after it is created

             We have 2 components in a page:
                -A table component
                -A modal component

            We need inter-communication between them:
                we want to be able to open the modal (for edit),
                we want when the modal closes (after edit or on close button click) to refresh (filter) the table data

            The table component has the following API [filter, clear, and we can click an item to opent the modal to edit it]
            The modal component has the following API [show, close, edit]

            The way we resovle the problem by using SafeSimpleEvent and EventProxy combo:
                * Important, because most of the javascript handlers belong to the modal, the actual event is created (As on object) inside the modal compoennt *

                1. In the parent component of the two, we need to create an EventProxy
                2. We pass the EventProxy object to the table component :
                    -here we subscribe to the event provided by the EventProxy object
                3. We pass the EventProxy object to the modal component :
                    -(here we have the actual event)
                    -in the ctor, we set the event on the given EventProxy object

                4. We are ready to listen and dispatch events 
                
            Afte the setup is done:
                5. On table component row, we rise an "edit" event to the EventProxy which is passed down to the wrapped event (this event is is implemented/created in the  modal component)
                6. On event "edit" callback (inside the modal component) we show the modal
                7. We edit and save the entity
                8. After we are done, we close the modal and rise the "filter" event (because we want to refresh the table data)
                9. The table component is notified and handles the "filter" event accordingly
        */
  }

  handleEvent = ({ type, payload }) => {
    switch (type) {
      case "show_dialog":
        {
          !this.state.showFlyout && this.clearData(true, false);
        }
        break;

      case "close_dialog":
        {
          this.state.showFlyout &&
            this.setState({
              showFlyout: true,
              isEdit: false,
            });
        }
        break;

      case "show_edit_dialog":
        {
          !this.state.showFlyout && this.clearData(true, true);
          this.getInfoCardData(payload.Id, payload.isReadonly);
        }
        break;

      default:
        {
        }
        break;
    }
  };

  handleClickCallbackCover = (url, file) => {
    this.setState({
      coverFile: file,
      coverUrl: url,
    });
  };
  handleClickCallbackVideoCover = (url, file) => {
    this.setState({
      videoCoverFile: file,
      videoCoverUrl: url,
    });
  };
  handleClickCallbackExternalVideoCover = (url, file) => {
    this.setState({
      externalVideoCoverFile: file,
      externalVideoCoverUrl: url,
    });
  };
  handleChangeDays = (event) => {
    this.setState({ days: event.target.value });
  };
  handleChangeInfo = (event) => {
    this.setState({ info: event.target.value });
  };
  handleChangeMessage = (event) => {
    this.setState({ message: event.target.value });
  };
  handleChangeVideoUrl = (event) => {
    this.setState({ videoUrl: event.target.value });
  };
  handleChangeExternalUrl = (event) => {
    this.setState({ externalUrl: event.target.value });
  };
  handleChangeExternalResourceUrl = (event) => {
    this.setState({ externalResourceUrl: event.target.value });
  };
  handleChangeTime = (_time) => {
    this.setState({ time: _time });
  };
  handleClose = () => {
    this.setState({
      showFlyout: false,
    });

    this.props.onClose && this.props.onClose();

    this.state.internalEventHandler.riseEvent({ type: "filter" });
  };
  onSaveCallback = (result) => {
    if (ValidResult(result)) {
      this.props.toastr.ShowSuccess(
        `Successfully ${this.state.isEdit ? "saved" : "created"} the info card`
      );
      this.handleClose();
    } else {
      this.props.toastr.ShowError(
        `Failed to ${this.state.isEdit ? "saved" : "created"} the info card`
      );
    }
  };
  onError = (err) => {
    this.props.toastr.ShowError("An error occurred, please try again");
  };

  clearData = (showFlyout, isEdit) => {
    !showFlyout && (showFlyout = this.state.showFlyout);
    !isEdit && (isEdit = this.state.isEdit);

    this.setState({
      id: null,
      coverUrl: null,
      videoCoverUrl: null,
      externalVideoCoverUrl: null,
      coverFile: null,
      videoCoverFile: null,
      externalVideoFile: null,
      days: null,
      info: null,
      time: null,
      message: null,
      videoUrl: null,
      externalUrl: null,
      externalResourceName: null,

      showFlyout,
      isEdit,
      isReadonly: false,
    });
  };
  setData = (data, isReadonly) => {
    const time = StrDateToMoment(data.Time);
    this.setState({
      id: data.Id,
      coverUrl: data.ImageUrl,
      videoCoverUrl: data.VideoCaptionUrl,
      externalVideoCoverUrl: data.ResourceCaptionUrl,
      coverFile: null,
      videoCoverFile: null,
      externalVideoFile: null,
      days: data.Day,
      info: data.Info,
      time,
      message: data.Message,
      videoUrl: data.VideoUrl,
      externalUrl: data.ResourceUrl,
      externalResourceName: data.ResourceName,
      isReadonly,
    });
  };
  getInfoCardData = (id, isReadonly) => {
    PageStateHandler.SetLoading();

    AxiosHelper.post(`/InformationCard/GetInformatioCardData?id=${id}`)
      .then((result) => {
        if (ValidResult(result.data)) {
          this.setData(result.data.Data, isReadonly);
        } else {
          this.props.toastr.ShowError(
            "Failed to get the Information Card data"
          );
          this.handleClose();
        }

        PageStateHandler.SetReady();
      })
      .catch((err) => {
        this.props.toastr.ShowError("An error orccurred, please try again");
        PageStateHandler.SetReady();
      });
  };

  render() {
    var props = this.props;

    const hiddenData = {
      ["PathwayId"]: props.pathwayId,
    };

    if (this.state.isEdit) {
      hiddenData.Id = this.state.id;
    } else {
      hiddenData.Id = null;
    }
    return (
      <Flyout
        isVisible={this.state.showFlyout}
        id="edit-info-card-flyout"
        showCloseButton={true}
        onClose={this.handleClose}
        size="half"
      >
        <FlyoutHead>
          <h2 className="title-flyout">
            {this.state.isReadonly ? "" : this.state.isEdit ? "Edit" : "Create"}{" "}
            Info Card Message
          </h2>
        </FlyoutHead>
        <FlyoutBody>
          <Form
            name="InformationCardUIModel"
            isReadonly={this.state.isReadonly}
            url="InformationCard/Save"
            containsFile={true}
            dontClear={true}
            callback={this.onSaveCallback}
            onError={this.onError}
            hiddenData={hiddenData}
            align="2-col"
          >
            <FormEntry
              text="Cover*"
              name="Image"
              value={this.state.coverUrl}
              validationName="ImageUrl"
            >
              <GalleryItemPickerComponent
                dialogTitle="Pick an image"
                type={GalleryItemType.Image}
              />
            </FormEntry>
            <FormEntry text="Day*" name="Day" value={this.state.days}>
              <InputComponent />
            </FormEntry>
            <FormEntry text="Time*" name="Time" value={this.state.time}>
              <TimePickerComponent format="HH:mm" />
            </FormEntry>
            <FormEntry text="Title*" name="Info" value={this.state.info}>
              <TextAreaComponent />
            </FormEntry>
            <FormEntry
              text="External Resource Name"
              name="ResourceName"
              value={this.state.externalResourceName}
            >
              <InputComponent />
            </FormEntry>
            <FormEntry
              text="External Resource URL"
              name="ResourceUrl"
              value={this.state.externalUrl}
            >
              <InputComponent />
            </FormEntry>
            <FormEntry
              text="Video"
              name="Video"
              value={this.state.videoUrl}
              validationName="VideoUrl"
            >
              <GalleryItemPickerComponent
                dialogTitle="Pick a video"
                type={GalleryItemType.Video}
                hasRemove={true}
                legend={"Video Cover will also be removed."}
              />
            </FormEntry>
            <FormEntry
              text="Video Cover"
              name="VideoCaption"
              value={this.state.videoCoverUrl}
              validationName="VideoCaptionUrl"
              className="margin-bottom"
            >
              <GalleryItemPickerComponent
                dialogTitle="Pick an image"
                type={GalleryItemType.Image}
                hasRemove={true}
              />
            </FormEntry>
            <FormEntry
              text="Message*"
              name="Message"
              value={this.state.message}
            >
              <TextAreaComponent />
            </FormEntry>
            <FormEntry
              text="External Resource Cover"
              name="ResourceCaption"
              value={this.state.externalVideoCoverUrl}
            >
              <GalleryItemPickerComponent
                dialogTitle="Pick an image"
                type={GalleryItemType.Image}
                hasRemove={true}
              />
            </FormEntry>
          </Form>
        </FlyoutBody>
        <FlyoutFooter> </FlyoutFooter>
      </Flyout>
    );
  }
}

export default SaveInformationCardDialogComponent;
