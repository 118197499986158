import axios from "axios";

//window.ENVIRONMENT_URL = "http://suxeed.surgeon/api";
const settings = {
  baseURL: window.ENVIRONMENT_URL,
  withCredentials: true,
  crossDomain: true,
};

export default axios.create(settings);
