import React, { Component, useReducer, useContext } from "react";
import "antd/dist/antd.css";
import "./css/base/cssReset.css";
import "./css/base/basicStructure.css";
import { SiteContext } from "./components/contexts";
import SafeSimpleEvent from "./components/SafeSimpleEvent";
import AxiosHelper from "./utils/AxiosHelper";
import LoginPage from "./pages/LoginPage";
import LoadingPage from "./pages/LoadingPage";
import ChooseHospitalsPage from "./pages/ChooseHospitalsPage";
import ChooseSurgeonsPage from "./pages/ChooseSurgeonsPage";

import SiteState from "./components/SiteState";
import Root from "./Root";

import translations from "./components/translations";
import fillTemplate from "es6-dynamic-template";
import { DEFAULT_USER_PICTURE_URL } from "./AppConstants";
import "./css/fonts.css";
import { ValidResult } from "./utils/Utils";
import NotificationsComponent, {
  Notifications,
} from "./components/NotificationsComponent";
import PageState from "./components/PageState";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ActivateAcountPage from "./pages/ActivateAcountPage";
import ConfirmModule from "./components/ConfirmModule";
import { DialogManagerComponent } from "./components/DialogManager";

import { DEFAULT_USER_PICTURE_URL_ABSOLUTE } from "../src/AppConstants";

const THEME_LINKS = {
  ["default"]: "/themes/default",
};

function SiteReducer(state, { type, payload }) {
  switch (type) {
    case "SET_TRANSLATION": {
      const newTranslation = state.translations.all.find(
        (t) =>
          t.locale === payload.name &&
          (!payload.version || payload.version === t.version)
      );

      if (!newTranslation) {
        return state;
      }

      return {
        ...state,
        activeTranslation: newTranslation,
      };
    }
    case "SET_THEME":
      return {
        ...state,
        Theme: payload,
      };
    case "SET_AUTH_STATE":
      return {
        ...state,
        siteState: payload.siteState,
        screenIsReady: payload.screenIsReady,
        showSwapHospital: payload.showSwapHospital,
        showSwapSurgeon: payload.showSwapSurgeon,
        auth: {
          ...state.auth,
        },
        userData: payload.userData,
      };
    case "LOGOUT":
      return {
        ...state,
        siteState: SiteState.LoggedOut,
        auth: {
          ...state.auth,
        },
        userData: {},
      };
    case "SET_USER_DATA":
      return {
        ...state,
        siteState: SiteState.LoggedIn,
        userData: payload.userData,
      };
    case "SET_SHOW_SWAP_HOSPITAL":
      return {
        ...state,
        siteState: SiteState.LoggedIn,
        showSwapHospital: payload.showSwapHospital,
      };
    case "SET_SHOW_SWAP_SURGEON":
      return {
        ...state,
        siteState: SiteState.LoggedIn,
        showSwapSurgeon: payload.showSwapSurgeon,
      };
    case "SET_SITE_STATE":
      return {
        ...state,
        siteState: payload.siteState,
      };
    default:
      return state;
  }
}

const _siteEvents = new SafeSimpleEvent();
export const SITE_EVENTS = _siteEvents;

function SiteWrapper(props) {
  const [state, dispatch] = useReducer(SiteReducer, {
    Theme: "default",
    Themes: THEME_LINKS,
    screenIsReady: false,
    showSwapHospital: false,
    siteState: SiteState.Loading,
    auth: {},
    userData: {
      profilePictureUrl: DEFAULT_USER_PICTURE_URL,
    },
    translations: translations,
    activeTranslation: translations.active,
    routerEvents: new SafeSimpleEvent(),
  });

  props.didMountEvent.subscribe(() => {
    //@TODO check if we are on /resetpassword?etc and set the site state appropiately
    if (window.location.href.indexOf("Surgeon/RessetPassword") !== -1) {
      //we are on resetpassword page

      dispatch({
        type: "SET_AUTH_STATE",
        payload: {
          siteState: SiteState.RessetPasswordPage,
          screenIsReady: true,
          userData: {},
        },
      });

      return;
    }

    if (window.location.href.indexOf("Patient/ActivateAccount") !== -1) {
      //we are on resetpassword page
      dispatch({
        type: "SET_AUTH_STATE",
        payload: {
          siteState: SiteState.RenderActivateAccount,
          screenIsReady: true,
          userData: {},
        },
      });

      return;
    }

    AxiosHelper.post("Account/IsAuthorized")
      .then((response) => {
        if (ValidResult(response.data)) {
          var _siteState;
          var showSwapHospital = false;
          var showSwapSurgeon = false;
          if (response.data.Data.HospitalId) {
            _siteState = SiteState.LoggedIn;
          } else _siteState = SiteState.ChooseHospital;

          if (response.data.Data.HospitalCount > 1) {
            showSwapHospital = true;
          }

          if (response.data.Data.SurgeonId) {
            _siteState = SiteState.LoggedIn;
          } else _siteState = SiteState.ChooseSurgeon;

          if (response.data.Data.SurgonCount > 1) {
            showSwapSurgeon = true;
          }

          dispatch({
            type: "SET_AUTH_STATE",
            payload: {
              siteState: _siteState,
              screenIsReady: true,
              userData: {
                email: response.data.Data.Email,
                name: response.data.Data.Name,
                profilePictureUrl: response.data.Data.ProfilePictureUrl
                  ? response.data.Data.ProfilePictureUrl
                  : DEFAULT_USER_PICTURE_URL_ABSOLUTE(),
              },
            },
          });
          dispatch({
            type: "SET_SHOW_SWAP_HOSPITAL",
            payload: {
              showSwapHospital: showSwapHospital,
            },
          });

          dispatch({
            type: "SET_SHOW_SWAP_SURGEON",
            payload: {
              showSwapSurgeon: showSwapSurgeon,
            },
          });
          return true;
        } else {
          dispatch({
            type: "SET_AUTH_STATE",
            payload: {
              siteState: SiteState.LoggedOut,
              isAuthenticated: false,
              screenIsReady: true,
              userData: {},
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: "SET_AUTH_STATE",
          payload: {
            siteState: SiteState.LoggedOut,
            isAuthenticated: false,
            screenIsReady: true,
            userData: {},
          },
        });
      });
  });

  const toastr = useContext(Notifications);

  const OnLoginSuccess = ({
    Email,
    Name,
    ProfilePictureUrl,
    HospitalCount,
    SurgonCount,
    PracticeId,
  }) => {
    var _siteState = SiteState.LoggedIn;
    var showSwapHospital = false;
    var showSwapSurgeon = false;

    if (HospitalCount > 1) {
      _siteState = SiteState.ChooseHospital;
      showSwapHospital = true;
    } else if (SurgonCount > 1) {
      _siteState = SiteState.ChooseSurgeon;
      showSwapSurgeon = true;
      showSwapHospital = false;
    }

    setTimeout(() => {
      dispatch({
        type: "SET_AUTH_STATE",
        payload: {
          siteState: _siteState,
          screenIsReady: true,
          showSwapHospital: showSwapHospital,
          showSwapSurgeon: showSwapSurgeon,
          userData: {
            email: Email,
            name: Name,
            profilePictureUrl: ProfilePictureUrl
              ? ProfilePictureUrl
              : DEFAULT_USER_PICTURE_URL_ABSOLUTE(),
            practiceId: showSwapSurgeon ? PracticeId : "",
          },
        },
      });
    }, 500);
  };

  const LogOut = () => {
    //make call to serve to logout then on success dispatch to the reducer the "LOGOUT" action
    AxiosHelper.post("Account/LogOff")
      .then((response) => {
        if (ValidResult(response.data)) {
          dispatch({
            type: "LOGOUT",
            payload: {
              reasone: "none",
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "LOGOUT",
          payload: {
            reasone: "none",
          },
        });
      });
  };

  const RenderLoggedIn = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <Root routerEvents={state.routerEvents} />
      </>
    );
  };

  const RenderLoggedOut = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/login.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/loginAndRegister.css`}
        />
        <LoginPage SetSiteState={SetSiteState} />
      </>
    );
  };

  const ChooseHospital = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/site.css`}
        />
        <ChooseHospitalsPage
          userData={state.userData}
          showSwapHospital={state.showSwapHospital}
          dispatch={dispatch}
          showSwapSurgeon={state.showSwapSurgeon}
        />
      </>
    );
  };

  const ChooseSurgeon = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/site.css`}
        />
        <ChooseSurgeonsPage
          userData={state.userData}
          showSwapSurgeon={state.showSwapSurgeon}
          dispatch={dispatch}
        />
      </>
    );
  };

  const RenderLoading = () => (
    <>
      <LoadingPage />
    </>
  );

  const SetSiteState = (state) => {
    dispatch({
      type: "SET_AUTH_STATE",
      payload: {
        siteState: state,
        screenIsReady: true,
        userData: {},
        auth: {},
      },
    });
  };

  const RederRessetPasswordPage = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/loginAndRegister.css`}
        />
        <ResetPasswordPage toastr={toastr} SetSiteState={SetSiteState} />
      </>
    );
  };

  const RederForgotPasswordPage = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/loginAndRegister.css`}
        />
        <ForgotPasswordPage toastr={toastr} SetSiteState={SetSiteState} />
      </>
    );
  };

  const RenderActivateAccount = () => {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/theme.css`}
        />
        <link
          rel="stylesheet"
          type="text/css"
          href={`${state.Themes[state.Theme]}/loginAndRegister.css`}
        />
        <ActivateAcountPage toastr={toastr} SetSiteState={SetSiteState} />
      </>
    );
  };

  const Render = () => {
    if (!state.screenIsReady) {
      return RenderLoading();
    }

    switch (state.siteState) {
      case SiteState.LoggedIn:
        return RenderLoggedIn();
      case SiteState.LoggedOut:
        return RenderLoggedOut();
      case SiteState.Loading:
        return RenderLoading();
      case SiteState.ChooseHospital:
        return ChooseHospital();
      case SiteState.AfterChooseHospital:
        return RenderLoggedIn();
      case SiteState.RessetPasswordPage:
        return RederRessetPasswordPage();
      case SiteState.RenderActivateAccount:
        return RenderActivateAccount();
      case SiteState.ForgotPassword:
        return RederForgotPasswordPage();
      case SiteState.ChooseSurgeon:
        return ChooseSurgeon();
      case SiteState.AfterChooseSurgeon:
        return ChooseHospital();
      default:
        return RenderLoading();
    }
  };

  const GetTranslationKey = (key, def, group, props) => {
    const activeTranslation = state.activeTranslation;

    if (activeTranslation.locale === "default") {
      return def ? def : "No default value set for [" + key + "]";
    }

    if (!group) {
      group = "General";
    }

    if (!activeTranslation[group]) {
      return activeTranslation.locale + " dosn't contain group [" + group + "]";
    }

    let value = activeTranslation[group][key];

    value = value ? value : def;

    if (props && value !== def) {
      value = fillTemplate(value, props);
    }

    return value;
  };

  const TranslateValidation = (field, fieldName) => {
    let result = {};

    const validations = field.ValidationRules;
    const rules = field.Rules;

    let args = {
      fieldName,
    };

    rules.forEach((rule) => {
      const keys = Object.keys(rule.ValidationParameters);
      if (!keys || !keys.length) {
        return;
      }

      keys.forEach((key) => {
        args[key] = rule.ValidationParameters[key];
      });
    });

    for (var key in validations) {
      if (key === "data-val-regex-pattern") {
        result[key] = validations[key];
        continue;
      }

      try {
        result[key] = GetTranslationKey(
          key,
          validations[key],
          "Validations",
          args
        );
      } catch (ex) {}
    }

    return result;
  };

  return (
    <SiteContext.Provider value={{ dispatch, state, LogOut, OnLoginSuccess }}>
      {
        <>
          <NotificationsComponent />
          <ConfirmModule />
          <DialogManagerComponent />
          <PageState>{Render()}</PageState>
        </>
      }
    </SiteContext.Provider>
  );
}

class App extends Component {
  state = {
    didMountEvent: SafeSimpleEvent(),
  };

  componentDidMount = () => {
    this.state.didMountEvent.riseEvent();
  };

  render() {
    return <SiteWrapper didMountEvent={this.state.didMountEvent} />;
  }
}

export default App;
