import React, { Component, useContext, useReducer } from "react";
import AxiosHelper from "../utils/AxiosHelper";
import SiteState from "../components/SiteState";
import { Notifications } from "../components/NotificationsComponent";
import { PageStateHandler } from "../components/PageState";

import PaginationHandler from "../components/filterAndPagination/PaginationHandler";
import FilterHandler from "../components/filterAndPagination/FilterHandler";
import TextFilterEntry from "../components/filterAndPagination/components/TextFilterEntry";

const ChooseSurgeonTableContext = React.createContext(
  FilterHandler.BuildContextValue()
);

const _reducer = function (state, action) {
  switch (action.type) {
    case "SET_DATA": {
      return {
        ...state,
        surgeons: action.payload.surgeons,
        screenIsReady: true,
      };
    }
  }
};

function _ChooseSurgeonsPage(props) {
  const [state, dispatch] = useReducer(_reducer, {
    surgeons: props.userData.surgeons ? props.userData.surgeons : [],
    screenIsReady: true,
    practiceId: props.userData.practiceId ? props.userData.practiceId : "",
  });

  //   if (state.surgeons.length == 0) {
  //     getSurgeons();
  //   }

  const hiddenData = {
    ActiveIndex: 1,
    PracticeId: props.userData.practiceId ? props.userData.practiceId : "",
  };

  const toastr = useContext(Notifications);

  function getSurgeons() {
    PageStateHandler.SetLoading();
    AxiosHelper.post("/Surgeon/GetSurgeonsPage", {
      ActiveIndex: 1,
      PracticeId: state.practiceId,
    })
      .then((response) => {
        dispatch({
          type: "SET_AUTH_STATE",
          payload: {
            surgeons: response.data.Data.Data,
          },
        });
        PageStateHandler.SetReady();
      })
      .catch((error) => {
        PageStateHandler.SetTo("ERROR");
      });
  }

  const setSurgeon = (surgeonId, surgeonAspnetId) => {
    PageStateHandler.SetLoading();
    AxiosHelper.post(
      `Account/SetSurgeon?surgeonId=${surgeonId}&surgeonAspnetId=${surgeonAspnetId}`
    )
      .then((response) => {
        if (response && !response.Success) {
          PageStateHandler.SetTo("ERROR");
        }

        var data = response ? response.data.Data : undefined;

        props.dispatch({
          type: "SET_AUTH_STATE",
          payload: {
            siteState:
              data && data.HospitalCount > 1
                ? SiteState.AfterChooseSurgeon
                : SiteState.LoggedIn,
            screenIsReady: true,
            showSwapSurgeon: props.showSwapSurgeon,
            showSwapHospital: false,
            userData: {
              ...props.userData,
              surgeonId: surgeonId,
              hospitalId: 0,
            },
          },
        });
        toastr.ShowSuccess("All data will be saved on the choosen surgeon");
        PageStateHandler.SetReady();
      })
      .catch((error) => {
        PageStateHandler.SetTo("ERROR");
      });
  };

  const renderSurgeonsTable = (items) => {
    return (
      <>
        <div className="s-hospitals">
          {items.map((surgeon) => {
            return (
              <div
                className="s-hospital-item"
                onClick={() => {
                  setSurgeon(surgeon.Id, surgeon.AspnetUserId);
                }}
                key={surgeon.Id}
                id={surgeon.Id}
              >
                <label>{surgeon.Name}</label>
                <label>{surgeon.Email}</label>
                <label>{surgeon.Phone}</label>
                <label>{surgeon.Address}</label>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return state.screenIsReady ? (
    <>
      <div className="s-hospitals-head"></div>
      <div className="s-hospitals-container">
        <FilterHandler context={ChooseSurgeonTableContext}>
          <TextFilterEntry name="Name" friendlyName="Name" />
        </FilterHandler>
        <PaginationHandler
          takeCount={10}
          showPanelIfNeeded={true}
          context={ChooseSurgeonTableContext}
          id="faq-pagination"
          url="/Surgeon/GetSurgeonsPage"
          processor={renderSurgeonsTable}
          hiddenData={hiddenData}
        />
      </div>
    </>
  ) : null;
}

class ChooseSurgeonsPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href="/themes/default/chooseHospital.css"
        />
        {<_ChooseSurgeonsPage {...this.props} />}
      </>
    );
  }
}

export default ChooseSurgeonsPage;
