import React, { Component, useReducer, useContext } from 'react'
import { DatePicker } from 'antd';

function _reducer(state, action) {
    switch (action.type) {
        case 'CLEAR': {
            return {
                ...state,
                value: ""
            }
        }
        case 'VALUE_CHANGED': {
            return {
                ...state,
                value: action.value
            }
        }
    }

    return state
}

export default function DateFilterEntry(props) {
    const [state, dispatch] = useReducer(_reducer, {
        name: props.name,
        value: props.value ? props.value : "",
    })

    const { collectEvent, clearEvent, filterEvent } = props

    collectEvent.subscribe((formData) => {
        formData[state.name] = state.value
    }, props.name)


    const onValueChanged = (date, dateStr) => {
        dispatch({
            type: 'VALUE_CHANGED',
            value: date
        })
       filterEvent.riseEvent({ [state.name]: date })
    }

    const clear = () => {
        dispatch({
            type: 'CLEAR'
        })
    }

    clearEvent.subscribe(() => {
        clear()
    }, props.name);

    return (
        <div className="d-filter-entry d-text-filter-entry">
            <div>
                <label>{props.friendlyName ? props.friendlyName : props.name}</label>
            </div>
            <DatePicker
                value={state.value} onChange={onValueChanged}

            />
        </div>
    )
}
