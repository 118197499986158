import React, { Component } from 'react'

export function ActionGroup(props) {
    return (
        <div className="d-page-heading-action-group">
            {props.children}
        </div>
    )
}

export function PageTitle(props) {
    return (
        <div className="d-page-heading-title" style={{["display"]: "inline-block"}}>
            <label>{props.text}</label>
        </div>
    )
}

export class PageHeading extends Component {
    render() {
        return (
            <div className="d-page-heading">
                {this.props.children}
            </div>
        )
    }
}

export default PageHeading
