import React, { Component, useReducer, useContext } from 'react'
import Select2Component from '../../Select2Component';
import EntitiesSelect2Component from '../../EntitySelect2Component';
import { stat } from 'fs';
//import Select2Component from '.../'

function _reducer(state, action) {
    switch (action.type) {
        case 'CLEAR': {
            return {
                ...state,
                value: []
            }
        }
        case 'VALUE_CHANGED': {
            return {
                ...state,
                value: action.value
            }
        }
    }

    return state
}

export default function MultiselectFilterEntry(props) {
    const [state, dispatch] = useReducer(_reducer, {
        name: props.name,
        value: [],
    })

    const { collectEvent, clearEvent } = props

    collectEvent.subscribe((formData) => {
        formData[state.name] = state.value
    })

    const onValueChanged = (ev, { value }) => {
        dispatch({
            type: 'VALUE_CHANGED',
            value: value
        })
    }

    const clear = () => {
        dispatch({
            type: 'CLEAR'
        })
    }

    clearEvent.subscribe(() => {
        clear()
    }, props.name);

    return (
        <div className="d-filter-entry d-multiselect-filter-entry" >
            <label>{props.friendlyName ? props.friendlyName : props.name}</label>
            <EntitiesSelect2Component
                value={state.value}
                onChange={onValueChanged}
                placeholder={props.placeholder}
                url={props.url}

            />
        </div>
    )
}
