import React, { Component, useContext, useReducer } from 'react';
import GeneralInformationTable, { GeneralInformationTableContext } from '../components/table/GeneralInformationTable';
import SaveFaqItemDialogComponent from '../components/SaveFaqItemDialogComponent';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';


import { Notifications } from "../components/NotificationsComponent"
import PrimaryButton from '../components/buttons/PrimaryButton';
import EventProxy from "../components/EventProxy"
import ObjectWrapper from '../components/ObjectWrapper';


function _GeneralInformationPage(props) {

    const toastr = useContext(Notifications)
    const tableContext = useContext(GeneralInformationTableContext)

    props.toastr.setObject(toastr)

    return (null)
}

class GeneralInformationPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            saveFAQFlyoutEvents: EventProxy()
        }

        this.toastr = ObjectWrapper()
        this.tableContext = ObjectWrapper()
    }

    render() {
        return (
            <>
                <PageHeading >
                    <PageTitle text="General Information" />
                    <ActionGroup>
                        <PrimaryButton value="Create General Information" click={() => { this.state.saveFAQFlyoutEvents.riseEvent({ type: "open" }) }} />
                    </ActionGroup>
                </PageHeading>
                <GeneralInformationTable events={this.state.saveFAQFlyoutEvents} editEvent={this.state.saveFAQFlyoutEvents} />
                <SaveFaqItemDialogComponent onClose={() => {
                    this.state.saveFAQFlyoutEvents.riseEvent({ type: "filter" })
                }} toastr={this.toastr} events={this.state.saveFAQFlyoutEvents} editEvent={this.editFaqItemEvent} />
                <_GeneralInformationPage toastr={this.toastr} />
            </>);
    }
}

export default GeneralInformationPage;
