import React, { Component } from 'react'
import uuid from "uuid"

import { ToArray } from "../../utils/Utils"
import "../../css/basicTabs.css"

import TabHeader from './components/TabHeader';

export class Tabs extends Component {
    constructor(props) {
        super(props)

        let activeTabId = this.props.activeTabId
        const children = ToArray(this.props.children)

        if (!activeTabId) {
            activeTabId = children.length ? children[0].props.id : null
        }

        this.state = {
            activeTabId
        }

        if (this.props.externalEvents) {
            this.props.externalEvents.subscribe(this.handleEvents, this.props.name)
        }
    }

    componentDidMount = () => {

    }

    handleEvents = ({ type, payload }) => {
        if (type === `${this.props.name}_select_tab`) {
            if (payload) {
                this.setActiveTabId(payload)
            }
            else {
                const children = ToArray(this.props.children)
                if (children.length) {
                    this.setActiveTabId(children[0].props.id)
                }
            }
        }
        else if (type === `${this.props.name}_set_selected_tab`) {
            //this.state.activeTabId = payload
            this.setState({ activeTabId: payload })
        }
    }

    initActiveTabId = (activeTabId) => {
        if (!this.state.activeTabId) {
            this.setState({ activeTabId })
        }
    }

    setActiveTabId = (activeTabId) => {
        if (this.state.activeTabId == activeTabId) {
            return;
        }

        if (this.props.externalEvents) {
            var result = this.props.externalEvents.riseEventCheckSpecificResult({
                type: `${this.props.name}_on_tab_will_change`,
                payload: activeTabId
            }, false)
            if (result) {
                return
            }
        }

        this.setState({ activeTabId })

        if (this.props.externalEvents) {
            setTimeout(
                () => {
                    this.props.externalEvents.riseEvent({
                        type: `${this.props.name}_on_tab_did_change`,
                        payload: activeTabId
                    })
                }, 1)
        }
    }

    componentDidMount = () => {
        if (!ToArray(this.props.children).length) {
            return
        }

        if (this.props.startTabId) {
            this.setState({ activeTabId: this.props.startTabId })
        } else {
            const firstChild = this.props.children.length ? this.props.children[0] : this.props.children;
            this.setState({ activeTabId: firstChild.props.id })
        }
    }

    render() {
        const tabs = ToArray(this.props.children)
        const processedTabs = []

        for (let index = 0; index < tabs.length; index++) {
            const tab = tabs[index]

            if (!tab) {
                continue
            }

            const id = tab.props.id ? tab.props.id : uuid()

            processedTabs.push(
                React.cloneElement(tab, { id, key: id })
            )
        }

        const activeTab = processedTabs.find(e => {
            return e.props.id === this.state.activeTabId
        })

        const tabHeaders = processedTabs.map((e, i) => {
            var fullTabTitle = e.props.friendlyName;

            return (
                <TabHeader text={fullTabTitle}
                    activeTabId={this.state.activeTabId}
                    id={e.props.id}
                    setActiveTabIdHandler={this.setActiveTabId}
                    key={e.props.id}
                />
            )
        })

        return (
            <div className={`${this.props.className ? this.props.className : ""} d-tabs`} >
                <div className="d-tabs-header" >
                    {tabHeaders}
                    {
                        this.props.isDynamic ? (
                            <label className="d-tabs-tab-header" title={`New ${this.props.entityName}`}>
                                <label className="d-tabs-new-action" onClick={() => {
                                    this.props.externalEvents.riseEvent({
                                        type: `${this.props.name}_on_new_tab`
                                    })
                                }} ></label>
                            </label>
                        ) : null
                    }
                </div>
                <div className="d-tab-container" > {activeTab ? activeTab : null}</div>
            </div >
        )
    }
}

export default Tabs