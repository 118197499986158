import React, { Component } from 'react'

export class TabHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.id,
            activeTabId : props.activeTabId
        }
    }

    setActive = () => {
        this.props.setActiveTabIdHandler(this.props.id)
    }

    componentWillReceiveProps({ activeTabId }) {
        this.setState({ activeTabId })
    }

    isActive = () => this.state.activeTabId === this.props.id

    render() {
        return (
            <label className="d-tabs-tab-header" isactive={this.isActive() ? "True" : "False"} onClick={this.setActive} >
                <label>{this.props.text }&nbsp;&nbsp;&nbsp;&nbsp;</label>
            </label>
        )
    }
}

export default TabHeader
