import React, { Component } from 'react'

export class TextAreaComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value ? props.value : "",
            placeholder: props.placeholder ? props.placeholder : ""
        }

        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, this.props.name)
        }

        if (this.props.validateEvent) {
            this.props.validateEvent.subscribe(this.validateEntry, this.props.name)
        }

        if (this.props.clearEvent) {
            this.props.clearEvent.subscribe(this.clearData, this.props.name)
        }

    }

    validateEntry = (formData) => {
        if (this.props.setValidationMessageEvent && Array.isArray(this.props.rules)) {
            var requiredRule = this.props.rules.find((r) => r.ValidationType === "required");
            if (requiredRule) {
                if (!this.state.value || !this.state.value.length) {
                    this.props.setValidationMessageEvent.riseEvent(requiredRule.ErrorMessage)
                    return false
                }
            }
        }

        return true
    }

    collectData = (formData) => {
        //here we process the value so we set the final version to the form(the version of the value that is posted to the server)
        //(eg. if this is a datepicker, set the iso-string or moment or actual js date)

        formData[this.props.name] = this.state.value
    }

    clearData = (reasone) => {
        this.setState({ value: "" })
    }

    onChange = (ev) => {
        this.setState({ value: ev.target.value })
    }

    render() {
        const validations = this.props.validations ? this.props.validations : {}
        return (
            <textarea
                {...validations}
                value={this.state.value}
                onChange={this.onChange}
                readOnly={this.props.isReadonly}
                placeholder={this.state.placeholder}
            >
            </textarea>
        )
    }
}

export default TextAreaComponent
