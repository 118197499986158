import uuid from "uuid"

export default function EventProxy() {
    let event = null
    let pendingSubscribers = []

    const riseEvent = (payload) => {
        return event && event.riseEvent(payload)
    }
 
    const defferedRiseEvent = (payload) => {
        return event && event.defferedRiseEvent(payload)
    }

    const riseEventCheckSpecificResult = (payload, specificResult) => {
        return event && event.riseEventCheckSpecificResult(payload, specificResult)
    }

    const setEvent = (e) => {
        event = e

        pendingSubscribers.forEach((sub) => {
            event.subscribe(sub.callback, sub.id)
        })

        pendingSubscribers = []
    }

    const getEvent = () => {
        return event
    }

    const subscribe = (callback, id) => {
        if (event) {
            return event.subscribe(callback, id)
        }

        if (!id) {
            id = uuid();
            pendingSubscribers.push({
                id,
                callback
            })

            return;
        }

        let subscriber = pendingSubscribers.find((t) => t.id === id)
        if (subscriber) {
            subscriber.callback = callback
        }
        else {
            subscriber = {
                id,
                callback
            }

            pendingSubscribers.push(subscriber)
        }


        return id;
    }

    const unsubscribe = (id) => {
        if (event) {
            return event.unsubscribe(id)
        }

        let subscriber = pendingSubscribers.find((t) => t.id === id)
        if (subscriber) {
            subscriber.callback = null
        }

    }

    return {
        setEvent,
        riseEvent,
        defferedRiseEvent,
        riseEventCheckSpecificResult,
        getEvent,
        subscribe,
        unsubscribe
    }
}