import React, { Component } from 'react'
import PrimaryButton from './buttons/PrimaryButton';

import "../css/confirmModule.css"

export const Confirm = {
    Show: () => { }
}

export class ConfirmModule extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
            payload: null,
            handler: () => { },
            data: {
                title: "Are you sure",
                message: "Do you wnat to delete asdasdasdasdasasasd?"
            }
        }

        Confirm.Show = this.Show
    }

    Show = (title, message, handler, payload) => {
        this.state.handler = handler

        this.setState({
            isVisible: true,
            payload,
            data: {
                message,
                title
            }
        })
    }

    clear = () => {
        this.state.handler = () => { }
        this.state.payload = null
        this.state.data = {}
    }

    render() {
        return this.state.isVisible ? (
            <div className="d-confirm-overlay">
                <div className="d-confirm">
                    <div className="d-confirm-title">
                        <label>{this.state.data.title}</label>
                    </div>
                    <div className="d-confirm-body">
                        <label>{this.state.data.message}</label>
                    </div>
                    <div className="d-confirm-footer">
                        <PrimaryButton value="Ok" click={() => {
                            this.state.handler({ type: "ok", payload: this.state.payload })

                            this.setState({
                                isVisible: false
                            })
                            this.clear()
                        }} />
                        <PrimaryButton value="Cancel" click={() => {
                            this.state.handler({ type: "cancel", payload: this.state.payload })

                            this.setState({
                                isVisible: false
                            })
                            this.clear()
                        }} />
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default ConfirmModule
