import React from 'react'
import AxiosHelper from '../utils/AxiosHelper';
import { Dropdown } from 'semantic-ui-react'
import { debuglog } from 'util';
import { ToArray } from '../utils/Utils';

class EntitiesSelectComponent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            screenIsReady: false,
            placeholder: this.props.placeholder ? this.props.placeholder : '',
            url: this.props.url ? this.props.url : '',
            options: this.props.options ? this.props.options : [],
            selectedOptions: this.props.value !== undefined ? this.props.value : null,
            entityKey: this.props.entityKey ? this.props.entityKey : ''
        };

        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, this.props.name)
        }

        if (this.props.validateEvent) {
            this.props.validateEvent.subscribe(this.validateEntry, this.props.name)
        }

        if (this.props.clearEvent) {
            this.props.clearEvent.subscribe(this.clearData, this.props.name)
        }

        if (this.props.changeTagsEvent) {
            this.props.changeTagsEvent.subscribe(this.handleChangeTags, this.props.name)
        }
    }

    onChange = (e, { value }) => {
        this.props.onChange ? this.props.onChange(e, { value }) : this.setState({ selectedOptions: value });
    };

    validateEntry = (formData) => {
        if (this.props.setValidationMessageEvent) {
            var rulesArray = ToArray(this.props.rules)
            var requiredRule = rulesArray.find((r) => r.ValidationType === "required");
            if (requiredRule) {
                if (this.state.selectedOptions === null || this.state.selectedOptions === undefined) {
                    this.props.setValidationMessageEvent.riseEvent(requiredRule.ErrorMessage)
                    return false
                }
            }
        }

        return true
    }

    collectData = (formData) => {
        formData[this.props.name] = this.state.selectedOptions;
    }

    clearData = (reasone) => {
        this.setState({ selectedOptions: [] })
    }

    handleChangeTags = ({ type, payload }) => {
        if (type == "tags_changed") {
            this.getData(payload)
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            selectedOptions: props.value,
            placeholder: props.placeholder,
            url: props.url,
            entityKey: props.entityKey
        })
    }

    getData = (tags) => {
        AxiosHelper.post(this.state.url,
            {
                ["tagIds"]: tags ? tags : []
            })
            .then(response => {
                let optionsForDropdown = response.data.map(entity => {
                    return {
                        key: entity.Id,
                        text: entity[this.state.entityKey] ? entity[this.state.entityKey] : "Invalid key",
                        value: entity.Id
                    }
                });

                const value = this.state.selectedOptions ? (optionsForDropdown.find((t) => t.value === this.state.selectedOptions) ? this.state.selectedOptions : null) : null

                this.setState({
                    options: optionsForDropdown,
                    screenIsReady: true,
                    selectedOptions: value
                });
            }).catch(function (err) {

            });
    }

    componentDidMount() {
        if (this.state.url) {
            this.getData();
        }
    }

    render() {
        return (
            <Dropdown
                disabled={this.props.isReadonly}
                onAddItem={this.props.onAddItem}
                value={this.state.selectedOptions}
                onChange={this.onChange}
                placeholder={this.state.placeholder}
                options={this.state.options}
                className="select-tags"
                fluid selection
                clearable
            />)
    }

}

export default EntitiesSelectComponent;