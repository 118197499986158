import React, { Component } from 'react'

export class Footer extends Component {
  render() {
    return (
      <div className="site-footer">
        <h4> SuXeed</h4>
      </div>
    )
  }
}

export default Footer


