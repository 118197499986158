import React, { Component } from "react";
import { Form } from "../form/Form";
import FormEntry from "../form/FormEntry";
import InputComponent from "./InputComponent";
import { ValidResult } from "../utils/Utils";

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  onLogin = (result) => {
    if (ValidResult(result)) {
      this.props.toastr.ShowSuccess("Logged in successfully");
      this.props.onSuccess({
        Email: result.Data.Email,
        Name: result.Data.Name,
        ProfilePictureUrl: result.Data.ProfilePictureUrl,
        HospitalCount: result.Data.HospitalCount,
        SurgonCount: result.Data.SurgonCount,
        PracticeId: result.Data.PracticeId,
      });
    } else {
      this.props.toastr.ShowError("Login failed");
    }
  };

  onError = (err) => {
    this.props.toastr.ShowError("An error occurred, please try again");
  };

  render() {
    return (
      <Form
        name="LoginForm"
        url="Account/Login"
        align="1-col"
        dontClear={true}
        containsFile={false}
        callback={this.onLogin}
        onError={this.onError}
      >
        <FormEntry name="Email" text="Email">
          <InputComponent type="text" />
        </FormEntry>
        <FormEntry name="Password" text="Password">
          <InputComponent type="password" />
        </FormEntry>
      </Form>
    );
  }
}

export default LoginForm;
