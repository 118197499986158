import React, { Component, useContext, useReducer } from 'react';
import { QuestionnaireTableContext } from '../components/contexts'
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import QuestionnairesAnswersTableWithFilter from '../components/table/QuestionnairesAnswersTableWithFilter';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import { Notifications } from '../components/NotificationsComponent';
import PrimaryButton from '../components/buttons/PrimaryButton';

const _reducer = function (state, action) {
    switch (action.type) {
        case 'SET_DATA': {
            return {
                ...state,
                screenIsReady: true
            }
        }

        default:
            return state
    }
}

function _QuestionnairesAnswerPage(props) {
    //initial state
    const [state, dispatch] = useReducer(_reducer, {
        screenIsReady: true
    })

    const questionnaireTableContext = useContext(QuestionnaireTableContext)

    const toastr = useContext(Notifications)

    props.didMountEvent.subscribe(() => {
        questionnaireTableContext.filterEvent.defferedRiseEvent();
    })

    return (state.screenIsReady ? (
        <>
            <PageHeading >
                <PageTitle text="Questionnaire Answers" />
            </PageHeading>
            <QuestionnairesAnswersTableWithFilter
                toastr={toastr}
                context={QuestionnaireTableContext}
            />
        </>
    ) : null
    )
}

class QuestionnairesAnswerPage extends Component {

    constructor(props) {
        super(props)

        this.didMountEvent = new SafeSimpleEvent();
    };

    componentDidMount() {
        this.didMountEvent.riseEvent();
    };

    render() {
        return (<_QuestionnairesAnswerPage {...this.props} didMountEvent={this.didMountEvent} />)
    }
}

export default QuestionnairesAnswerPage;
