import React from "react"
import FilterHandler from "./filterAndPagination/FilterHandler";


export const SiteContext = React.createContext();
export const PaginationContext = React.createContext();
export const HospitalTableContext = React.createContext(FilterHandler.BuildContextValue());
export const SurgeonTableContext = React.createContext(FilterHandler.BuildContextValue());
export const PatientTableContext = React.createContext(FilterHandler.BuildContextValue());
export const QuestionnaireTableContext = React.createContext(FilterHandler.BuildContextValue());
export const GalleryGridContext = React.createContext(FilterHandler.BuildContextValue());

export default function dummy() {}
    // HospitalTableContext = React.createContext()
    // PatientsContext : React.createContext()
    // PathwaysContext : React.createContext()
    // QuestionnairesContext : React.createContext()
// GeneralInformationContext : React.createContext()
//module.exports = {
//    SiteContext : React.createContext(),
//    PaginationContext : React.createContext(),
//    HospitalTableContext : React.createContext(FilterHandler.BuildContextValue()),
//    SurgeonTableContext : React.createContext(FilterHandler.BuildContextValue()),
//}

//export { SiteContext, TestPaginationContext, PatientsContext, PathwaysContext, QuestionnairesContext, GeneralInformationContext }