import React, { Component, useReducer, useContext } from 'react';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import { QuestionnaireTableContext } from "../contexts"
import SafeSimpleEvent from '../SafeSimpleEvent';
import { EntityStatus, ValidResult } from '../../utils/Utils';
import { Notifications } from '../NotificationsComponent';
import { withRouter } from "react-router";
import Moment from 'react-moment';


function _QuestionnairesAnswersTable(props) {


    const toastr = useContext(Notifications)
    const questionnaireTableContext = useContext(QuestionnaireTableContext)

    const renderQuestionnaireTable = (items) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }
        return (
            <table className="d-table">
                <thead>
                    <tr className="d-table-row">
                        <th></th>
                        <th>Patient Name</th>
                        <th>Age</th>
                        <th>Surgery Date</th>
                        <th>Pathway</th>
                        <th>Surgeon Name</th>
                        <th>Questionnaire</th>
                        <th>Answer Time</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(answer => {
                        return (
                            <tr className="d-table-row" key={answer.Id} >
                                <th></th>
                                <td>{answer.PatientName}</td>
                                <td>{answer.Age}</td>
                                <td><Moment format="D.MM.YYYY">
                                    {answer.SurgeryDate}
                                </Moment></td>
                                <td>{answer.PathwayName}</td>
                                <td>{answer.SurgeonName}</td>
                                <td>{answer.QuestionnaireName}</td>
                                <td><Moment format="D.MM.YYYY HH:mm">
                                    {answer.AnswerTime}
                                </Moment></td>
                                <th></th>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
    return (
        <>
            <PaginationHandler
                id="questionnaires-pagination"
                url="PatientQuestionnaireAnswer/GetQuestionnairesAnswer"
                processor={renderQuestionnaireTable}
                activeIndex={EntityStatus.Active}
                context={QuestionnaireTableContext}
            />
        </>
    )
}


class QuestionnairesAnswersTable extends Component {
    constructor(props) {
        super(props)

        this.didMountEvent = SafeSimpleEvent()
    }

    componentDidMount(props) {
        this.didMountEvent.riseEvent()
    }

    render() {
        return (
            <_QuestionnairesAnswersTable {...this.props} didMountEvent={this.didMountEvent} />
        );
    }
}

export default withRouter(QuestionnairesAnswersTable);