import React, { Component } from "react"
import { Link } from "react-router-dom"
import { debug } from "util";
import { All } from "../../utils/Utils";

class MenuItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.Id,
            activeId: this.props.activeId
        }

        const { pathname } = window.location
        if (!this.props.isLogo) {
            if ((!this.props.includedLocaitons && pathname === this.props.to) ||
                (this.props.includedLocaitons &&
                    All(this.props.includedLocaitons, (l) => {
                        return pathname.indexOf(l) !== -1;
                    })) &&
                    pathname.indexOf(this.props.to) !== -1) {
                this.state.activeId = this.props.Id
            }
        }
    }

    setActiveId = () => {
        this.props.click(this.state.id)
    }

    isActive = () => !this.props.isLogo && this.state.activeId == this.props.Id;

    componentDidMount = () => {

    }

    componentWillReceiveProps({ activeId }) {
        this.setState({ activeId })
    }

    render() {
        return (
            <li id={this.props.Id} className="menu-item" isselected={this.isActive() ? "True" : "False"} onClick={this.setActiveId}>
                <Link to={this.props.to}>
                    <span>
                        <span className={this.props.itemClass + " menu-item-icon"}></span>
                        <span className="menu-item-text">{this.props.text}</span>
                    </span>
                </Link>
            </li>
        )
    }
}

export default MenuItem