import React, { Component, useReducer } from 'react'
import SafeSimpleEvent from './SafeSimpleEvent';

import "../css/flyout.css"

export function FlyoutHead(props) {
    return (
        <div className="d-flyout-head">
            {
                props.showCloseButton ? (<>
                    <label className="close-flouyt" onClick={props.close}  > </label>
                </>) : (<></>)
            }
            <div className="d-f-h-body">
                {props.children}
            </div>
        </div>
    )
}

export function FlyoutBody(props) {
    return (
        <div className="d-flyout-body">
            {props.children}
        </div>
    )
}

export function FlyoutFooter(props) {
    return (
        <div className="d-flyout-footer">
            {props.children}
        </div>
    )
}

function _reducer(state, { type, payload }) {
    switch (type) {

        default:
            return state;
    }
}

function _Flyout(props) {
    const [state, dispatch] = useReducer(_reducer, {

    })

    const didMountSubId = props.didMountEvent.subscribe(() => {
        //on didMount
    }, props.id ? props.id : null)

    const _props = { ...props }
    delete _props.children;

    return (
        <div className="d-flyout">
            {
                React.Children.map(props.children, (c) =>
                    React.cloneElement(c, { ..._props })
                )
            }
        </div>
    )
}
class Flyout extends Component {
    constructor(props) {
        super(props)
        this.didMountEvent = SafeSimpleEvent();

        this.state = {
            isVisible: props.startVisible ? props.startVisible : false,
            isOpen: false
        }
    }

    closeFlyout = () => {
        this.setState({
            isVisible: false,
            isOpen: false
        })

        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    openFlyout = () => {
        this.setState({ isOpen: true })
    }

    componentWillReceiveProps({ isVisible }) {
        this.setState({ isVisible })

        if (isVisible) {
            setTimeout(() => { this.openFlyout() }, 1);
        }
        else {
            this.setState({ isOpen: false })
        }
    }

    render() {
        const props = { ...this.props, didMountEvent: this.didMountEvent }

        return this.state.isVisible ? (
            <div className="d-flyout-overlay" isactive={this.state.isVisible ? "True" : "False"} isopen={this.state.isOpen ? "True" : "False"} data-size={props.size} id={this.props.id}>
                <_Flyout  {...props} close={this.closeFlyout} />
            </div>
        ) : (null)
    }
}

export default Flyout