import uuid from "uuid"
import { Where } from "../utils/Utils";

export default function SafeSimpleEvent() {
    const subscribers = []

    const subscribe = (callback, id) => {
        if (!id) {
            id = uuid();
            subscribers.push({
                id,
                callback
            })
            return;
        }

        let subscriber = subscribers.find((t) => t.id === id)
        if (subscriber) {
            subscriber.callback = callback
        }
        else {
            subscriber = {
                id,
                callback
            }

            subscribers.push(subscriber)
        }

        return id;
    }

    const clear = ()=>{
        subscribers.length = 0
    }

    const unsubscribeByName = (name, _contains = false, _delete = false) => {
        const toDelete = Where(subscribers, (t) => {
            return _contains ? (t.id.indexOf(name) !== -1) : (t.id === name)
        })

        toDelete.forEach(subscriber => {
            if (!subscriber) {
                return
            }

            if (!_delete) {
                subscriber.callback = null
            }
            else {
                const index = subscribers.indexOf(subscriber)
                if (index >= 0) {
                    subscribers.splice(index, 1)
                }
            }
        })
    }

    const unsubscribe = (id, _delete = false) => {
        let subscriber = subscribers.find((t) => t.id === id)
        if (subscriber) {
            if (!_delete) {
                subscriber.callback = null
            }
            else {
                const index = subscribers.indexOf(subscriber)
                if (index >= 0) {
                    subscribers.splice(index, 1)
                }
            }
        }
    }

    const riseEvent = (payload) => {
        subscribers.forEach(subscriber => {
            if (typeof (subscriber.callback) !== "function") {
                return
            }
            subscriber.callback(payload)
        });
    }

    const riseUnsubscribeEvent = (payload) => {
        const toUnsubscribe = []
        
        subscribers.forEach(subscriber => {
            if (typeof (subscriber.callback) !== "function") {
                return
            }
            if(subscriber.callback(payload)){
                toUnsubscribe.push(subscriber)
            }
        });

        toUnsubscribe.forEach(sub => unsubscribe(sub.id, true))
    }

    const defferedRiseEvent = (payload) => {
        setTimeout(() => {
            subscribers.forEach(subscriber => {
                subscriber.callback && subscriber.callback(payload)
            });
        }, 1);
    }

    const riseEventCheckSpecificResult = (payload, specificResult) => {
        if (specificResult === undefined) {

            riseEvent(payload)

            return null
        }

        let outResult = null
        for (let index = 0; index < subscribers.length; index++) {
            const subscriber = subscribers[index];

            if (typeof (subscriber.callback) !== "function") {
                continue
            }

            const result = subscriber.callback(payload)
            if (result === specificResult && outResult === null) {
                outResult = subscriber
            }
        }

        return outResult
    }

    const riseEventGetFirstDefinedResult = (payload) => {
        for (let index = 0; index < subscribers.length; index++) {
            const subscriber = subscribers[index];

            if (typeof (subscriber.callback) !== "function") {
                continue
            }

            const result = subscriber.callback(payload)
            if (result !== undefined) {
                return result
            }
        }
    }

    return {
        clear,
        subscribers,
        subscribe,
        riseEvent,
        defferedRiseEvent,
        riseUnsubscribeEvent,
        unsubscribe,
        riseEventCheckSpecificResult,
        riseEventGetFirstDefinedResult,
        unsubscribeByName
    }
}