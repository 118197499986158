import React, { Component } from 'react'

export class Tab extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: props.id
        }
    }

    isActive = () => {
        return this.props.activeTabId == this.state.id
    }

    render() {

        return (
            <div className="d-tab-body" id={this.props.id}>
                {this.props.children}
            </div>
        )
    }
}

export default Tab
