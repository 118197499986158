import React, { Component } from 'react';
import FormEntry from '../../form/FormEntry';
import { Form } from '../../form/Form';
import InputComponent from '../../components/InputComponent';
import DatePickerComponent from '../../components/DatePickerComponent';
import EntitiesSelect2Component from '../EntitySelect2Component';
import EntitiesSelectComponent from '../EntitySelectComponent';
import SafeSimpleEvent from '../SafeSimpleEvent';
import DialogManager from "../DialogManager"

class SavePatientForm extends Component {
    constructor(props) {
        super(props)
        let options = [{ text: 'AM', key: 0, value: 0 }, { text: 'PM', key: 1, value: 1 }]
        this.state = {
            patient: props.patient,
            changeTagsEvent: SafeSimpleEvent(),
            formEvents: SafeSimpleEvent(),
            options: options
        }
    }
    render() {
        const patient = this.state.patient;

        const hiddenData = {
            Id: patient ? patient.id : null,
            ActivePathwayId: patient ? patient.activePathwayId : null,
            Code: patient ? patient.code : null
        }
        return (
            <Form name="PatientUIModel" url="Patient/Save"
                containsFile={false}
                callback={this.props.onSaveCallback}
                onSubmitStart={this.props.onSubmitStart}
                externalEvents={this.state.formEvents}
                dontClear={true}
                onError={this.props.onError}
                hiddenData={hiddenData}>
                <FormEntry name="FirstName" text="First Name*" value={patient ? patient.firstName : null} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="LastName" text="Last Name*" value={patient ? patient.lastName : null} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="Email" text="Email*" value={patient ? patient.email : null} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="PhoneNumber" text="Phone*" value={patient ? patient.phone : null} >
                    <InputComponent />
                </FormEntry>
                <FormEntry name="DateOfBirth" text="Date of birth*" value={patient ? patient.dateOfBirth : null} >
                    <DatePickerComponent />
                </FormEntry>
                <FormEntry name="DateOfSurgery" text="Surgery date*" value={patient ? patient.dateOfSurgery : null} >
                    <DatePickerComponent />
                </FormEntry>
                <FormEntry name="SurgeryTime" text="Surgery time*" value={patient ? patient.timeOfSurgery : null} >
                    <EntitiesSelect2Component
                        placeholder="Select Questionnaire"
                        options={this.state.options}
                        entityKey="text"
                        notMultiple={true}
                    />
                </FormEntry>
                <FormEntry
                    name="TagIds"
                    action={(
                        <span onClick={
                            () => {
                                DialogManager.Show(
                                    "tag_dialog",
                                    ({ type, payload }) => {
                                        if (type === "save_tag_success") {
                                            this.state.formEvents.riseEvent({
                                                type: "TagIds_add_option",
                                                payload: {
                                                    key: payload.Id,
                                                    text: payload.Name,
                                                    value: payload.Id
                                                }
                                            })
                                        }
                                    }
                                )
                            }
                        }>+Create Tag</span>
                    )}
                    text="Tags*" value={patient ? patient.tags : null}>
                    <EntitiesSelect2Component
                        placeholder="Select Tags"
                        url="Tag/GetAll"
                        onChangeEx={(e, value) => {
                            this.state.changeTagsEvent.riseEvent({ type: "tags_changed", payload: value })
                        }}
                    />
                </FormEntry>
                <FormEntry name="ActivePathwayId" text="Pathway*" value={patient ? patient.activePathwayId : null}>
                    <EntitiesSelectComponent
                        placeholder="Select Pathway"
                        url="Pathway/GetSelect2Entities"
                        entityKey="Name"
                        changeTagsEvent={this.state.changeTagsEvent}
                    />
                </FormEntry>

                {patient ?
                    (<></>) :
                    (
                        <FormEntry name="Code" text="Code*">
                            <InputComponent />
                        </FormEntry>
                    )
                }


            </Form>
        )
    }
}

export default SavePatientForm