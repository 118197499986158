import React from "react";
import FormEntry from "../../form/FormEntry";
import { Form } from "../../form/Form";
import GalleryItemPickerComponent from "../GalleryItemPickerComponent";
import InputComponent from "../InputComponent";
import { GalleryItemType } from "../../utils/Utils";

function SaveGalleryItemFrom(props) {
  debugger;
  var item = props.item;

  return (
    <Form
      name="GalleryItemUIModel"
      url="Gallery/AddGalleryItem"
      containsFile={true}
      dontClear={true}
      callback={props.onSaveCallback}
      onError={props.onError}
      align="1-col"
      hiddenData={{ Type: props.type, Id: item ? item.id : null }}
    >
      <FormEntry text="Name*" name="Name" value={item ? item.name : null}>
        <InputComponent />
      </FormEntry>
      {props.type == GalleryItemType.Image ? (
        <FormEntry
          text="Image*"
          name="GalleryItem"
          value={item ? item.src : null}
          validationName="Url"
        >
          <GalleryItemPickerComponent
            urlPostName="Url"
            showPickFromGallery={false}
            type={GalleryItemType.Image}
          />
        </FormEntry>
      ) : (
        <FormEntry
          text="Video*"
          name="GalleryItem"
          value={item ? item.src : null}
          validationName="Url"
        >
          <GalleryItemPickerComponent
            urlPostName="Url"
            showPickFromGallery={false}
            type={GalleryItemType.Video}
          />
        </FormEntry>
      )}
    </Form>
  );
}

export default SaveGalleryItemFrom;
