import React, { Component } from 'react'
import FormField from './FormFileld';
import { ToArray } from '../utils/Utils'
import SafeSimpleEvent from '../components/SafeSimpleEvent';

class FormEntry extends Component {
    constructor(props) {
        super(props)

        this.formContext = this.props.formContext
    }
    state = {
        value: this.props.value,
        validationData: {},
        setValidationMessageEvent: SafeSimpleEvent()
    }

    componentWillReceiveProps({ formData }) {
        if (!Array.isArray(formData)) {
            return
        }

        const validationData = formData.find(
            (v) => v.FiledName === this.props.name
        )

        this.setState({ validationData })
    }

    getAndTranslateValidation = (state) => {
        let validations = {}
        let rules = {}
        if (Array.isArray(state.formValidationData)) {
            let field = state.formValidationData.find(
                (v) => v.FiledName === (this.props.validationName ? this.props.validationName : this.props.name)
            )

            if (field) {
                if (this.props.translateValidations) {
                    validations = this.props.translateValidations(field, this.props.text)
                }
                else {
                    validations = field.ValidationRules
                }

                if (validations["data-val-date"]) {
                    delete validations["data-val-date"]
                }

                rules = field.Rules
            }
        }


        return { validations, rules };
    };

    render = () => {
        const { Consumer } = this.props.formContext

        return (
            <Consumer>
                {
                    ({ state, dispatch }) => {
                        const { validations, rules } = this.getAndTranslateValidation(state);
                        return (
                            <FormField
                                text={this.props.text}
                                name={this.props.name}
                                action={this.props.action}
                                className={"d-form-text-entry" + (this.props.className != undefined ? ' ' + this.props.className : '')}
                                key={this.props.key}
                                setValidationMessageEvent={this.state.setValidationMessageEvent}>
                                {
                                    ToArray(this.props.children).map(child => React.cloneElement(child,
                                        {
                                            formContext: this.props.formContext,
                                            value: this.props.value,
                                            name: this.props.name,
                                            collectDataEvent: this.props.collectDataEvent,
                                            clearEvent: this.props.clearEvent,
                                            validateEvent: this.props.validateEvent,
                                            validations,
                                            rules,
                                            onSubmit: this.props.onSubmit,
                                            setValidationMessageEvent: this.state.setValidationMessageEvent,
                                            isReadonly: this.props.isReadonly,
                                            externalEvents: this.props.externalEvents,
                                            placeholder: this.props.placeholder
                                        }))
                                }
                            </FormField>
                        )
                    }
                }
            </Consumer>
        )
    }
}

export default FormEntry
