import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

const UpdateStatusDialogComponent = (props) => (
    <Modal
        open={props.showRemoveEntityModal}
        onClose={props.handleClose}
        closeIcon
        basic size='small'>
        <Header content={props.action + " " + props.entity} />
        <Modal.Content>
            <p>
                Are you sure you want to {props.action.toLowerCase()} the {props.entity.toLowerCase()} ?
            </p>
            <p className="legend"> {props.legend} </p>
        </Modal.Content>
        <Modal.Actions>
            <Button color='green' inverted onClick={props.updateEntity}>
                <Icon name='checkmark' /> Yes
         </Button>
            <Button basic color='red' inverted onClick={props.cancelUpdate}>
                <Icon name='remove' /> No
         </Button>
        </Modal.Actions>
    </Modal>
)

export default UpdateStatusDialogComponent