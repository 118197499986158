import React, { Component, useReducer, useContext } from 'react'

function _reducer(state, action) {
    switch (action.type) {
        case 'CLEAR': {
            return {
                ...state,
                value: ""
            }
        }
        case 'VALUE_CHANGED': {
            return {
                ...state,
                value: action.value
            }
        }
    }

    return state
}

export default function TextFilterEntry(props) {
    const [state, dispatch] = useReducer(_reducer, {
        name: props.name,
        value: props.value ? props.value : "",
    })

    const { collectEvent, clearEvent, filterEvent } = props

    collectEvent.subscribe((formData) => {
        formData[state.name] = state.value
    }, props.name)

    const onValueChanged = (ev) => {
        dispatch({
            type: 'VALUE_CHANGED',
            value: ev.target.value
        })
    }

    const clear = () => {
        dispatch({
            type: 'CLEAR'
        })
    }

    clearEvent.subscribe(() => {
        clear()
    }, props.name);

    const filter = (e) => {
        if (e.key === 'Enter') {
            filterEvent.riseEvent();
        }
    }

    return (
        <div className="d-filter-entry d-text-filter-entry">
            <label>{props.friendlyName ? props.friendlyName : props.name}</label>
            <input type="text" value={state.value} onChange={onValueChanged} onKeyPress={filter} />
        </div>
    )
}
