import React, { Component } from 'react'
import { ToArray } from '../utils/Utils';

import { Link } from "react-router-dom"

function MoreActionsItem(props) {
    return (
        <div onClick={ev => props.onClick(props.id, props.obj)} className="d-more-action-item">
            <label>{props.text}</label>
        </div>
    )
}

function MoreActionsLinkItem(props) {
    return (
        <Link to={props.to}>
            <div className="d-more-action-item">
                <label>{props.text}</label>
            </div>
        </Link>
    )
}

class MoreActionsMenu extends Component {
    constructor(props) {
        super(props)

        this.ref = React.createRef(null)
    }

    state = {
        isVisible: false
    }

    onItemClick = (itemId, obj) => {
       if (this.props.callback) {
           this.props.callback(itemId, obj, this.props.activeIndex)
       }

       if (this.props.closeOnActionClick) {
           return
       }

       this.setState({ isVisible: false })
   }

    onClick = (ev) => {
        this.setState({ isVisible: !this.state.isVisible })
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        if (this.state.isVisible) {
            if (!this.ref.current.contains(e.target)) {
                this.setState({ isVisible: false })
                return;
            }
        }
    }

    render() {
        const title = this.props.title ? this.props.title : "More Actions"

        return (
            <div className="d-more-actions" ref={this.ref}>
                <label /*onClick={this.onClick}*/ title={title}></label>
                {
                    /*this.state.isVisible ?
                        (*/
                            <div className="d-more-action-list">
                                {
                                    ToArray(this.props.children).map(c =>
                                        React.cloneElement(c, { onClick: this.onItemClick })
                                    )
                                }
                            </div>
                        /*) :
                        (<></>)*/
                }
            </div>
        )
    }
}

export { MoreActionsMenu, MoreActionsItem, MoreActionsLinkItem }
