import React from 'react'
import { Dropdown } from 'semantic-ui-react'

class Select2Component extends React.Component {
    state = {
        value: this.props.value ? this.props.value : []
    }

    componentWillReceiveProps = ({ value }) => {
        this.setState({ value })
    }

    render() {
        const options = {}

        if (this.props.multiple) {
            options["multiple"] = "multiple"
        }
        return (
            <Dropdown
                disabled={this.props.isReadonly}
                onAddItem={this.props.onAddItem}
                value={this.props.value}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                options={this.props.options}
                className={"select-tags " + this.props.className} 
                fluid search selection
                clearable
                {...options}
            />
        );
    }
}


export default Select2Component;