import React, { Component, useContext } from 'react'
import androidstore from '../img/androidstore.png'
import applestore from '../img/applestore.png'
import PrimaryButton from "../components/buttons/PrimaryButton"
import AxiosHelper from '../utils/AxiosHelper';
import queryString from "query-string"
import { ValidResult } from '../utils/Utils';
import SiteState from '../components/SiteState';

class ActivateAccount extends Component {
    constructor(props) {
        super(props)

        this.state = {
            pageState: "ACTIVATING",

            renderers: {
                ["ACTIVATING"]: this.renderActivating,
                ["SUCCESS"]: this.renderSuccess,
                ["FAIL"]: this.renderFail,
                ["INVALID"]: this.renderInvalidData
            }
        }
    }

    componentDidMount = () => {
        //we make call tot the server to activate the given account

        const params = queryString.parse(window.location.href.split('?')[1])
        if (!params.userId || params.userId.trim() === "") {
            this.setState({ pageState: "INVALID" })
            return;
        }

        AxiosHelper.post(
            `Patient/ActivateAccount?userId=${params.userId}`).then(
                (result) => {
                    if (ValidResult(result.data)) {
                    this.props.toastr.ShowSuccess("Successfully activated your account")
                    this.setState({ pageState: "SUCCESS" })
                }
                else {
                    this.props.toastr.ShowError("Failed to activate your account")
                    this.setState({ pageState: "FAIL" })
                }

            }).catch(
            (err) => {

                this.props.toastr.ShowError("An error occurred, please try again")
                this.setState({ pageState: "FAIL" })
            });
    }

    renderActivating = () => {
        return (
            <h1>Activating your account...</h1>
        )
    }

    renderSuccess = () => {
        return (
            <>
                <h1>Your account was successfully activated</h1>
                <h1>Please download the app to get started</h1>
                <div className="logo-stores">
                    <a href=""><img src={androidstore} /></a>
                    <a href=""><img src={applestore} /></a>
                </div>
            </>
        )
    }

    renderFail = () => {
        return (
            <h1>Failed to activate your account.</h1>
        )
    }

    renderInvalidData = () => {
        return (
            <h1>Invalid data.</h1>
        )
    }

    render() {
        return (
            <div className="activate-account-container">
                {
                    this.state.renderers[this.state.pageState]()
                }
            </div >
        )
    }
}

export default ActivateAccount