import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Tabs from '../tabs/Tabs';
import Tab from '../tabs/components/Tab';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import InputComponent from '../InputComponent';
import { PaginationHandler } from '../filterAndPagination/PaginationHandler';
import FilterHandler from '../filterAndPagination/FilterHandler';
import { MoreAction, MoreActionsMenu, MoreActionsItem } from '../MoreActions';
import MultiselectFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';
import ListingTagsView from '../ListingTagsView';

export const GeneralInformationTableContext = React.createContext();
class GeneralInformationTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            contextValue: FilterHandler.BuildContextValue()
        }

        this.props.events.subscribe(this.handleEvent, "INFO_TABLE")
    }

    handleEvent = ({ type, payload }) => {
        if (type === "filter") {
            this.state.contextValue.filterEvent.riseEvent()
        }
    }

    onMenuItemClick = (menuItemId, obj) => {
        if (menuItemId === "edit") {
            if (this.props.editEvent) {
                this.props.editEvent.riseEvent({ type: "edit", payload: { menuItemId, obj } })
            }
        }
    }

    renderFAQTable = (items, paginationId) => {
        if (!items || !items.length) {
            return (
                <div className="d-pagination-message">
                    <label>No results.</label>
                </div>
            )
        }
        return (
            <table className="d-table" data-more-actions>
                <thead>
                    <tr className="d-table-row">
                        <th>TITLE</th>
                        <th>DESCRIPTION</th>
                        <th>TAGS</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {items.map(faqEntry => {
                        return (
                            <tr className="d-table-row" key={faqEntry.Id}>
                                <td onClick={() => { this.onMenuItemClick("edit", faqEntry) }}>{faqEntry.Title}</td>
                                <td className="ellipsis">
                                    {
                                        faqEntry.Description
                                    }
                                </td>
                                <td>
                                        <ListingTagsView tags={faqEntry.Tags} />
                                </td>
                                <td>
                                    <MoreActionsMenu callback={this.onMenuItemClick} >
                                        <MoreActionsItem text="Edit" id="edit" key={`${faqEntry.Id}-edit`} obj={faqEntry} />
                                    </MoreActionsMenu>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <>
                <GeneralInformationTableContext.Provider value={this.state.contextValue}>
                    <FilterHandler context={GeneralInformationTableContext}>
                        <TextFilterEntry name="Title" />
                        <MultiselectFilterEntry
                            name="Tags"
                            placeholder="Select Tag"
                            url="Tag/GetAll"
                        />
                    </FilterHandler>

                    <PaginationHandler
                        context={GeneralInformationTableContext}
                        id="faq-pagination"
                        url="/GeneralInformation/GetPage"
                        processor={this.renderFAQTable} />
                </GeneralInformationTableContext.Provider>
            </>
        );
    }
}

export default GeneralInformationTable;