import React, { Component, useReducer, useContext } from 'react';
import SafeSimpleEvent from '../SafeSimpleEvent';
import Tabs from '../tabs/Tabs';
import Tab from '../tabs/components/Tab';
import ImageTab from '../gallery/ImageTab'
import VideoTab from '../gallery/VideoTab'
import SaveGalleryItemFrom from ".././forms/SaveGalleryItemFrom"
import CreateEntityComponent from ".././CreateEntityComponent"
import { GalleryGridContext } from "../contexts"
import { Notifications } from '../NotificationsComponent'
import { GalleryItemType } from '../../utils/Utils'
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import AxiosHelper from '../../utils/AxiosHelper'
import UpdateStatusDialogComponent from '../UpdateStatusDialogComponent'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../../components/Flyout';
import { ValidResult } from '../../utils/Utils';

function _reducer(state, action) {
    switch (action.type) {
        case 'SET_STATE': {
            return {
                ...state,
                showDeleteModal: action.payload.showDeleteModal,
                itemToDelete: action.payload.itemToDelete,
                screenIsReady: true
            }
        }
        case 'SET_EDIT_DATA': {
            return {
                ...state,
                showEditModal: action.payload.showEditModal,
                type: action.payload.type != null ? action.payload.type : null,
                itemToUpdate: action.payload.itemToUpdate,
                screenIsReady: true
            }
        }

        default:
            return state
    }
}

function _GalleryGrid(props) {
    const galleryGridContainer = useContext(GalleryGridContext)
    const toastr = useContext(Notifications)
    const [state, dispatch] = useReducer(_reducer, {
        showDeleteModal: false,
        itemToDelete: null,
        showEditModal: false,
        itemToUpdate: null,
    })

    const deleteResource = () => {
        if (state.itemToDelete != null) {
            debugger
            AxiosHelper.post("Gallery/DeleteGalleryItem?id=" + state.itemToDelete
            ).then((result) => {
                galleryGridContainer.filterEvent.riseEvent();
                toastr.ShowSuccess("Successfully deleted the gallery item")
                dispatch({
                    type: "SET_STATE",
                    payload: {
                        showDeleteModal: false,
                        tagToDelete: null,
                    }
                })
            }).catch((ex) => {
                toastr.ShowSuccess("Error deleting the gallery item")
            })
        }
    }

    const cancelDelete = () => {
        dispatch({
            type: "SET_STATE",
            payload: {
                showDeleteModal: false,
                itemToDelete: null,
            }
        })
    }

    const showModal = (ev) => {
        var id = ev.target.id;
        dispatch({
            type: "SET_STATE",
            payload: {
                showDeleteModal: true,
                itemToDelete: id,
            }
        })
    }

    const showEditModal = (id, name, source, type) => {
        var galleryItem = { id: id, src : source, name: name };

        dispatch({
            type: "SET_EDIT_DATA",
            payload: {
                showEditModal: true,
                itemToUpdate: galleryItem,
                type: type
            }
        })
    }

    const onSaveCallback = (result) => {
        if (ValidResult(result)) {
            toastr.ShowSuccess("Successfully saved the gallery item");
            galleryGridContainer.filterEvent.riseEvent();
            handleClose();
        }
        else {
            toastr.ShowError("Failed to save the gallery item")
        }
    };

    const handleClose = () => {
        dispatch({
            type: "SET_EDIT_DATA",
            payload: {
                showEditModal: false,
                itemToUpdate: null,
            }
        })
    }

    return (
        <>
            <UpdateStatusDialogComponent
                action="Delete"
                entity="Gallery item"
                updateEntity={deleteResource}
                cancelUpdate={cancelDelete}
                showRemoveEntityModal={state.showDeleteModal}
            />

            <FilterHandler context={GalleryGridContext}>
                <TextFilterEntry name="Name" friendlyName="Name" />
            </FilterHandler>
            <Tabs startTabId="images-tab">
                <Tab friendlyName="Images" id="images-tab" >
                    <CreateEntityComponent
                        entity={"Image"}
                        context={galleryGridContainer}
                        toastr={toastr}>
                        <SaveGalleryItemFrom type={GalleryItemType.Image} />
                    </CreateEntityComponent>
                    <ImageTab galleryGrid={true} deleteResource={showModal} editItem={showEditModal} />
                </Tab>
                <Tab friendlyName="Videos" id="videos-tab" >
                    <CreateEntityComponent
                        entity={"Video"}
                        context={galleryGridContainer}
                        toastr={toastr}>
                        <SaveGalleryItemFrom
                            type={GalleryItemType.Video}
                        />
                    </CreateEntityComponent>
                    <VideoTab galleryGrid={true} deleteResource={showModal} editItem={showEditModal} />
                </Tab>
            </Tabs>

            <Flyout onClose={() => { handleClose() }} isVisible={state.showEditModal} id="edit-gallery-item" showCloseButton={true} size="small">
                <FlyoutHead><h2 className="title-flyout">Edit gallery item</h2></FlyoutHead>
                <FlyoutBody>
                    <SaveGalleryItemFrom
                        onSaveCallback={onSaveCallback}
                        item={state.itemToUpdate}
                        type={state.type}
                    />
                </FlyoutBody>
                <FlyoutFooter> </FlyoutFooter>
            </Flyout>
        </>
    )
}

class GalleryGrid extends Component {
    constructor(props) {
        super(props)

        this.willMountEvent = SafeSimpleEvent()
    }

    componentWillMount(props) {
        this.willMountEvent.riseEvent()
    }

    render() {
        return (
            <_GalleryGrid {...this.props} willMoundEvent={this.willMoundEvent} />
        );
    }
}

export default GalleryGrid;