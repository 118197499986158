import React from 'react'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../components/Flyout';
import { ToArray } from '../utils/Utils';
import { ValidResult } from '../utils/Utils';
import PrimaryButton from "./buttons/PrimaryButton"

class CreateEntityComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showCreateModal: false,
        };
    };

    onSaveCallback = (result) => {
        if (ValidResult(result)) {
            this.props.toastr.ShowSuccess("Successfully created the " + this.props.entity.toLowerCase())
            this.props.context.filterEvent.riseEvent();
            this.handleClose()

        }
        else if (result.StatusCode == 9) {
            this.props.toastr.ShowError("This email is already taken")
        }
        else {
            this.props.toastr.ShowError("Failed to create  " + this.props.entity.toLowerCase())
        }
    }

    handleClose = () => {
        this.setState({
            showCreateModal: false
        })
    }

    handleOpen = () => {
        this.setState({
            showCreateModal: true
        })
    }

    componentWillMount(props) {

    }

    render() {
        var props = this.props;
        var state = this.state;

        var children = ToArray(props.children);

        return (
            <>
                <PrimaryButton value={`Create ${props.entity}`} click={() => { this.handleOpen() }} />
                <Flyout onClose={() => { this.handleClose() }} isVisible={state.showCreateModal} id="edit-hospital-flout" showCloseButton={true} size="small">
                    <FlyoutHead><h2 className="title-flyout">Create {props.entity}</h2></FlyoutHead>
                    <FlyoutBody>
                        {
                            children.map((element, i) => (
                                React.cloneElement(element, { ...props, onSaveCallback: this.onSaveCallback, onError: props.onError })
                            ))
                        }
                    </FlyoutBody>
                    <FlyoutFooter> </FlyoutFooter>
                </Flyout>
            </>
        )
    }
}

export default CreateEntityComponent