import React, { Component } from 'react'
import SafeSimpleEvent from '../SafeSimpleEvent';
import { Form, FormPanel } from "../../form/Form"
import FormEntry from '../../form/FormEntry';
import InputComponent from '../InputComponent';
import uuid from "uuid"
import PrimaryButton from '../buttons/PrimaryButton';

export class NewCategoryDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isVisible: false,
            isEdit: false,
            internalEvents: SafeSimpleEvent(),

            data: {}
        }

        this.state.internalEvents.subscribe(this.handleEevent, "ME")

        if (this.props.eventProxy) {
            this.props.eventProxy.setEvent(this.state.internalEvents)
        }
    }

    clearAndClose = () => {
        this.setState({
            isVisible: false,
            isEdit: false,
            data: {}
        })
    }

    handleEevent = ({ type, payload }) => {
        if (type === "show_new_category_dialog") {
            this.setState({ isVisible: true, isEdit: false })
        }
        else if (type === "show_new_category_dialog_edit") {
            this.setState({
                isVisible: true,
                isEdit: true,
                data: {
                    ...payload
                }
            })
        }
        else if (type === "hide_new_category_dialog") {
            this.clearAndClose()
        }
    }

    hide = (payload) => {
        this.state.internalEvents.riseEvent({ type: "hide_new_category_dialog", payload: null })
    }

    onCreate = () => {
        const data = {}
        var result = this.state.internalEvents.riseEventCheckSpecificResult({
            type: "QuestionnaireCategoryForm_collect_data",
            payload: data
        }, false)
        if (result) {
            return
        }

        this.state.internalEvents.riseEvent({
            type: "on_submit_success",
            payload: data
        })
        
        this.hide();
    }

    render() {
        const hiddenData = {}

        if (this.state.isEdit) {
            hiddenData["Id"] = this.state.data.Id
        }

        return this.state.isVisible ? (
            <div className="d-dialog-overlay">
                <div className="d-dialog">
                    <div className="d-dialog-header">
                        <label>New Category</label>
                        <span className="d-dialog-close" title="Close" onClick={() => {
                            this.hide()
                        }}>X</span>
                    </div>
                    <div className="d-dialog-body">
                        <Form
                            id={uuid()}
                            name="QuestionnaireCategoryForm"
                            externalEvents={this.state.internalEvents}>
                            <FormEntry text="Name" name="Name" value={this.state.data.Name}>
                                <InputComponent />
                            </FormEntry>
                        </Form>
                    </div>
                    <div className="d-dialog-footer">
                        <PrimaryButton value="Create" click={this.onCreate} />
                    </div>
                </div>
            </div>
        ) : null
    }
}

export default NewCategoryDialog
