import React, { Component } from 'react'
import InputComponent from './InputComponent';
import FormEntry from '../form/FormEntry';
import uuid from "uuid";
import UpdateStatusDialogComponent from "../components/UpdateStatusDialogComponent"

function RemoveDiv(props) {
    return (<div className="remove-resource" onClick={() => props.remove(props.id)} id={props.id} > </div >)
}

class GeneralInformationResources extends Component {
    constructor(props) {
        super(props)
        this.state = {
            i: this.props.value ? this.props.value.length + 1 : 1,
            resources: this.props.value ? this.props.value : [],
            showModal: false,
            elementToRemove : 0
        }

        if (this.props.collectDataEvent) {
            this.props.collectDataEvent.subscribe(this.collectData, "Resource")
        }

        if (this.props.clearEvent) {
            this.props.clearEvent.subscribe(this.clearData, this.props.name)
        }

        if (this.props.externalEvents) {
            this.props.externalEvents.subscribe(this.handleEvents, this.props.name)
        }
    }

    clearData = (reasone) => {
        this.setState({ resources: [] })
    }

    collectDataAsObject = () => {
        var resourcesArray = [];
        var j;
        for (j = 1; j < this.state.i; j++) {
            var name = this.props.externalEvents.riseEventGetFirstDefinedResult({ type: 'collect_by_name', payload: 'Resource-Name-' + j });
            var url = this.props.externalEvents.riseEventGetFirstDefinedResult({ type: 'collect_by_name', payload: 'Resource-Url-' + j });
            resourcesArray.push({  Url: url, Name: name });
        }
        return resourcesArray;
    }

    collectData = (formData) => {
        var resourcesArray = this.collectDataAsObject();
        var jsonArray = JSON.stringify(resourcesArray)
        formData[this.props.name] = jsonArray
    }

    computeListOfResources = (formData) => {
        if (formData[this.props.name] && formData[this.props.name].length > 0 && formData[this.props.name][0] != undefined) {
            return [...formData[this.props.name]];
        }
        else return [];
    }

    addResourceUrl = () => {
        var list = this.collectDataAsObject();
       
        list.push({ Url: '', Name: '' });

        this.setState({
            resources: list,
            i: this.state.i + 1
        })
    }

    removeResourceUrl = () => {
        var i = this.state.elementToRemove;
        let list = this.collectDataAsObject();;
        list.splice(i - 1, 1);

        this.setState({
            resources: list,
            i: this.state.i - 1,
            showModal: false
        })
    }

    cancelDelete = () => {
        this.setState({ showModal: false })
    }

    showModal = (removeId) => {
        let list = this.collectDataAsObject();;
        this.setState({
            resources: list,
            showModal: true,
            elementToRemove: removeId
        })
    }

    render() {
        let i = 0;
        return (
            <>
                <UpdateStatusDialogComponent
                    action="Delete"
                    entity="resource url"
                    updateEntity={this.removeResourceUrl}
                    cancelUpdate={this.cancelDelete}
                    showRemoveEntityModal={this.state.showModal}
                />

                {
                    !this.state.resources ? <></> :
                        this.state.resources.map((resource) => {
                            i = i + 1;
                            return (
                                <>
                                    <label>
                                        <span className="resource-title"> Resource {i}</span>
                                        <RemoveDiv id={i} remove={this.showModal} />
                                    </label>
                                    <FormEntry name={"Resource-Name-" + i} key={uuid()} value={resource.Name} formContext={this.props.formContext} text={"Resource Name"} collectDataEvent={this.props.collectDataEvent} externalEvents={this.props.externalEvents} className="resource-name-container">
                                        <InputComponent className="resource-name" collectDataEvent={this.props.collectDataEvent} />
                                    </FormEntry>
                                    <FormEntry name={"Resource-Url-" + i} key={uuid()} value={resource.Url} text={"Resource Url"} formContext={this.props.formContext} collectDataEvent={this.props.collectDataEvent} externalEvents={this.props.externalEvents} className="resource-url-container">
                                        <InputComponent className="resource-url" collectDataEvent={this.props.collectDataEvent} />
                                    </FormEntry>
                                </>
                            )
                        })
                }
                <label className="d-form-entry-action position-relative">
                    <span className="span-add-resource" onClick={this.addResourceUrl}>+Add resource</span>
                </label>
            </>)

    }

}

export default GeneralInformationResources;