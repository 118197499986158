import React, { Component } from 'react'
import Flyout, { FlyoutHead, FlyoutBody, FlyoutFooter } from '../components/Flyout';
import AxiosHelper from '../utils/AxiosHelper';
import Moment from 'react-moment';
import axios from 'axios';
import moment from 'moment'

class SavePatientAnswersComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patient: {
                id: "",
                name: "",
            },
            questionnaires: []
        };
    };

    componentWillReceiveProps(props) {
        if (props.patient) {
            this.setState({
                patient: props.patient,
                showEditPatientModal: props.showEditPatientModal

            });
            AxiosHelper("PatientQuestionnaireAnswer/GetQuestionnaireDetailsForPatient?patientId=" + props.patient.id)
                .then((result) => {
                    if (result.data.Success == true) {
                        this.setState({
                            questionnaires: result.data.Data
                        })
                    }
                }).catch((ex) => {

                })
        }
    }

    downloadAnswers = (id, answerTime) => {
        axios({
            baseURL: window.ENVIRONMENT_URL,
            url: '/PatientQuestionnaireAnswer/DownloadQuestionnaireAnswers?questionnaireId=' + id + '&patientId=' + this.state.patient.id + "&answerTime=" + answerTime,
            method: 'GET',
            responseType: 'blob', // important
            withCredentials: true,
            crossDomain: true,
            headers: {
                'Accept': 'application/csv',
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'answers-' + id + '-' + this.state.patient.id + '.csv');
            document.body.appendChild(link);
            link.click();
        });
    }



    render() {
        var props = this.props;
        return (
            <Flyout onClose={() => { props.handleClose() }} isVisible={props.showPatientAnswer} id="show-patient-answer-flyout" showCloseButton={true} size="save-answers">
                <FlyoutHead><h2 className="title-flyout">{this.state.patient.name ? this.state.patient.name : ""} Questionnaires</h2></FlyoutHead>
                <FlyoutBody>
                    {
                        this.state.questionnaires.length == 0 ?
                            <div className="save-questionnaires-no-result">
                                <label>No results.</label>
                            </div>
                            :
                            <table className="d-download-answers-table" data-more-actions >
                                <thead>
                                    <tr className="">
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th className="padding-right-20 text-align-right">Answers</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.questionnaires.map((item) =>
                                            (
                                                <tr className="" key={item.QuestionnaireId}>
                                                    <td>{item.QuestionnaireName}</td>
                                                    <td><Moment format="D.MM.YYYY">
                                                        {item.AnswerTimeDate}
                                                    </Moment></td>
                                                    <td onClick={() => this.downloadAnswers(item.QuestionnaireId, item.AnswerTimeString)}>
                                                        <div title="Download" className="download"></div>
                                                    </td>
                                                </tr>
                                            )
                                        )
                                    }
                                </tbody>
                            </table>
                    }
                </FlyoutBody>
                <FlyoutFooter> </FlyoutFooter>
            </Flyout>
        );
    }
}

export default SavePatientAnswersComponent