import React, { Component } from 'react'
import "../css/siteState.css"
import "../css/loading.css"
import SafeSimpleEvent from './SafeSimpleEvent';
import ObjectWrapper from "./ObjectWrapper"

export const PageStateContext = React.createContext()
export const PageStateHandler = ObjectWrapper({
    SetTo: () => { },
    SetLoading: () => { },
    SetReady: () => { }
})

export class PageState extends Component {
    state = {
        currentState: "READY",
        setStateEvent: SafeSimpleEvent(),
        location: this.props.location ? this.props.location : "site",

    }

    constructor(props) {
        super(props)

        const pageStateRenderHandlers = {}

        pageStateRenderHandlers["LOADING"] = () => {
            return (
                <div className="loading-component">
                    <div className="d-page-state-loading loading-background">
                    </div>
                    <div className="loading-container">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        <br />
                    </div>
                </div>
            )
        }
        pageStateRenderHandlers["ERROR"] = () => {
            return (
                <div className="d-page-state-error">
                    <label>
                        {
                            (props.error ? props.error : "An error occurred.")
                        }
                    </label>
                </div>
            )
        }
        pageStateRenderHandlers["FAILED"] = () => {
            return (
                <div className="d-page-state-failed">
                    <label>
                        {
                            (props.error ? props.error : "Operation failed.")
                        }
                    </label>
                </div>
            )
        }

        this.pageStateRenderHandlers = pageStateRenderHandlers

        PageStateHandler.setObject({
            SetTo: this.SetTo,
            SetLoading: () => { this.SetTo("LOADING") },
            SetReady: () => { this.SetTo("READY") }
        })
    }

    SetTo = (currentState, location) => {
        this.setStateEventHandler({ currentState, location })
    }

    setStateEventHandler = ({ currentState, location }) => {
        !location && (location = this.state.location)
        this.setState({ currentState, location })
    }

    render() {
        const state = this.state
        const pageStateRenderHandlers = this.pageStateRenderHandlers

        return (
            <>
                <div className="d-page-state" isvisible={state.currentState === "READY" ? "False" : "True"}
                    data-z-index={this.state.location}
                >
                    {
                        state.currentState === "READY" ? null :
                            ((typeof (pageStateRenderHandlers[state.currentState]) === "function") ? (pageStateRenderHandlers[state.currentState]()) : null)
                    }
                </div>
                {
                    this.props.children
                }
            </>
        )
    }
}

export default PageState
