import React, { Component, useContext } from 'react';
import AxiosHelper from '../utils/AxiosHelper';

import QueryString from 'query-string';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import { Form } from '../form/Form';
import FormEntry from '../form/FormEntry';
import InputComponent from '../components/InputComponent';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Tabs from '../components/tabs/Tabs';
import QuestionnaireCategory from '../components/questionnaire/QuestionnaireCategory';
import Tab from '../components/tabs/components/Tab';

import uuid from "uuid"

import "../css/categoryDetails.css"
import DialogManager from '../components/DialogManager';
import { Notifications } from '../components/NotificationsComponent';
import ObjectWrapper from '../components/ObjectWrapper';
import { ValidResult } from '../utils/Utils';
import EntitiesSelect2Component from '../components/EntitySelect2Component';
import { PageStateHandler } from '../components/PageState';
import { Link } from "react-router-dom"

function _QuestionnaireDetails(props) {
    const toastr = useContext(Notifications)

    props.toastr.setObject(toastr)

    return null
}
class QuestionnaireDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEdit: false,
            questionnaireId: '',
            activeCategoryId: null,
            internalEvents: SafeSimpleEvent(),

            Id: null,
            TagIds: [],
            PathwayIds: [],
            IsTemplate: false,
            Name: "",
            categories: [],
            counter: 0,
            toastr: ObjectWrapper()
        }

        const params = QueryString.parse(this.props.location.search)
        if (params.questionnaireId) {
            this.state.isEdit = true
            this.state.Id = params.questionnaireId
            this.state.questionnaireId = params.questionnaireId
        }
        else {
            this.state.categories.push(this.createNewCategory("Simple"))
            this.state.activeCategoryId = this.state.categories[0].InternalId
        }

        this.state.internalEvents.subscribe(this.handleEvents, "ME")
    }

    componentWillMount = () => {
        this.refreshData()
    }

    updateCurrentCategoryData = (update) => {
        if (update) {

        }
        else {
            const category = { IsValid: true }
            var result = this.state.internalEvents.riseEventCheckSpecificResult({
                type: "collect_category_data",
                payload: category
            }, false)
            if (result) {
                category.IsValid = false
            }

            this.state.categories = this.state.categories.map(cat => {
                if (cat.InternalId === category.InternalId) {

                    const result = { ...cat, ...category }

                    result.Questions = category.Questions

                    return result
                }

                return cat
            })

            console.log(this.state.categories)
        }
    }

    updateFormData = (update) => {
        if (update) {

        }
        else {
            const data = {}
            this.state.internalEvents.riseEvent({ type: "QuestionnaireForm_collect_data_no_validation", payload: data })

            this.state.Name = data.Name
            this.state.PathwayIds = data.PathwayIds

            return data
        }
    }

    createNewCategory = (name) => {
        return {
            InternalId: uuid(),
            Id: null,
            Name: name,
            Desc: "",
            OrderIndex: 0,
            Questions: [],
            IsValid: false
        }
    }

    handleEvents = ({ type, payload }) => {
        switch (type) {
            case "Categories_on_new_tab": {
                DialogManager.Show(
                    "new_category_dialog",
                    ({ type, payload }) => {
                        if (type === "on_submit_success") {
                            const newCategory = this.createNewCategory(payload.Name)

                            this.updateCurrentCategoryData()
                            this.updateFormData()
                            this.state.categories.push(newCategory)

                            this.setActiveTabId(newCategory.InternalId)
                            this.forceUpdate()
                        }
                    })
            } break;
            case "Categories_on_tab_will_change": {
                this.updateFormData()
                this.updateCurrentCategoryData()
                return true
            }
            case "Categories_on_tab_did_change": {
                const activeCategoryId = this.state.internalEvents.riseEventGetFirstDefinedResult({
                    type: "get_active_category_id"
                })

                this.setActiveTabId(activeCategoryId)
                this.forceUpdate()
            } break;
            case "set_category_data_update":
                const update = true
            case "set_category_data": {
                this.state.categories.forEach(cat => {
                    if (cat.InternalId === payload.InternalId) {
                        cat.Id = payload.Id
                        cat.Name = payload.Name
                        cat.OrderIndex = payload.OrderIndex
                        cat.IsValid = payload.IsValid

                        cat.Questions = payload.Questions
                    }
                })

                if (update) {
                    console.log(this.state.categories)
                    this.forceUpdate()
                }
            }
            case "delete_questionnaire_category": {
                this.setState(prevState => ({
                    categories: prevState.categories.filter(cat => cat.InternalId !== payload)
                }))

                this.state.internalEvents.defferedRiseEvent({
                    type: "Categories_select_tab"
                })
            } break
            case "set_active_category_id": {
                this.state.activeCategoryId = payload
            } break;
            case "on_question_removed": {
                this.updateCurrentCategoryData()
            } break;
            default: { } break
        }
    }

    setData = (data) => {
        this.state.Name = data.Name
        this.state.PathwayIds = data.PathwayIds
        this.state.categories = data.QuestionnaireCategories
        this.state.IsTemplate = data.IsTemplate

        if(data.IsTemplate){
            data.QuestionnaireCategories.forEach(q => {
                q.IsTemplate = true

                q.Questions.forEach(qq => {
                    qq.IsTemplate = true

                    qq.QuestionChoices.forEach(c =>{
                        c.IsTemplate = true
                    })
                })
            })    
        }
        this.state.categories.forEach(cat => {
            cat.InternalId = cat.Id
            cat.IsValid = true
            if (cat.Questions) {
                cat.Questions.forEach(q => {
                    q.InternalId = q.Id
                    if (q.QuestionChoices) {
                        q.QuestionChoices.forEach(qc => {
                            qc.InternalId = qc.Id
                        })
                    }
                })
            }
        })

        if (this.state.categories.length) {
            this.setActiveTabId(this.state.categories[0].InternalId)
        }

        this.forceUpdate()
    }

    setActiveTabId = (id) => {
        this.state.activeCategoryId = id
        this.state.internalEvents.defferedRiseEvent({
            type: "Categories_set_selected_tab",
            payload: `${id}_tab`
        })
    }

    refreshData = () => {
        if (!this.state.isEdit) {
            return
        }

        PageStateHandler.SetLoading()
        AxiosHelper
            .get("/Questionnaire/GetQuestionnaire?id=" + this.state.Id)
            .then(({ data }) => {
                if (ValidResult(data)) {
                    this.setData(data.Data)
                }
                else {
                    this.state.toastr.ShowError("Failed to get the questionnaire data")
                }
                PageStateHandler.SetReady()
            })
            .catch((err) => {
                this.state.toastr.ShowError("Failed to get the questionnaire data")
                PageStateHandler.SetReady()
            })
    }

    componentWillMount() {

    };

    onSubmit = () => {
        const data = {}
        var result = this.state.internalEvents.riseEventCheckSpecificResult({ type: "QuestionnaireForm_collect_data", payload: data }, false)
        if (result) {
            this.state.toastr.ShowError("Please fill all required fields before sumbmiting")
            return false
        }

        this.state.PathwayIds = data.PathwayIds

        this.updateCurrentCategoryData()

        var invalidCategory = this.state.categories.find(t => !t.IsValid)
        if (invalidCategory) {

            this.state.toastr.ShowError("Please fill all required fields before sumbmiting")

            return false
        }

        data.EstimatedCompletionTime = 0
        data.TagIds = []
        data.QuestionnaireCategories = this.state.categories
        let copy = null

        if (!this.state.isEdit) {
            copy = JSON.parse(JSON.stringify(data))

            copy.QuestionnaireCategories.forEach(cat => {
                cat.Id = null

                cat.Questions.forEach(q => {
                    q.Id = null

                    q.QuestionChoices.forEach(o => {
                        o.Id = null
                    })
                })
            })
        }
        else {
            data.Id = this.state.Id
            copy = data
        }

        PageStateHandler.SetLoading();
        AxiosHelper
            .post("Questionnaire/Save", data)
            .then(({ data }) => {
                if (ValidResult(data)) {
                    this.state.toastr.ShowSuccess(`Successfully ${!this.state.isEdit ? "created" : "saved"} the questionnaire`)

                    if (!this.state.isEdit) {
                        const dest = {
                            Title: "Edit Questionnaire",
                            Url: `${document.location.href}?questionnaireId=${data.Data.Value}`
                        }

                        window.history.pushState(dest, dest.Title, dest.Url)

                        this.state.Id = data.Data.Value

                        this.updateFormData()
                        this.setState({ isEdit: true })
                    }
                }
                else {
                    this.state.toastr.ShowError(`Failed to ${!this.state.isEdit ? "create" : "save"} the questionnaire`)
                }
                PageStateHandler.SetReady();
            })
            .catch(err => {
                this.state.toastr.ShowError("An error occurred, please try again")
                PageStateHandler.SetReady();
            })

        return true
    }

    render() {
        const tabs = this.state.categories.map((cat) => (
            <Tab friendlyName={cat.Name} id={`${cat.InternalId}_tab`} key={cat.InternalId}>
                <QuestionnaireCategory isReadonly={this.state.IsTemplate} value={cat} externalEvents={this.state.internalEvents} key={`${cat.InternalId}_tab_category`} />
            </Tab>
        ))

        return (
            <>
                <_QuestionnaireDetails toastr={this.state.toastr} />
                <PageHeading>
                    <label><Link to="/questionnaires">Back</Link></label>
                    <PageTitle text={`${this.state.isEdit ? "Edit" : "Create"} Questionnaire`} />
                    <Form
                        isReadonly={this.state.IsTemplate}
                        id={`QuestionnaireForm`}
                        name="QuestionnaireForm"
                        externalEvents={this.state.internalEvents}
                        align="inline"
                    >
                        <FormEntry text="Title*" name="Name" value={this.state.Name}>
                            <InputComponent />
                        </FormEntry>
                        <FormEntry name="PathwayIds" text="Pathways*" value={this.state.PathwayIds}>
                            <EntitiesSelect2Component
                                placeholder="Select Pathawy"
                                url="Pathway/GetSelect2Entities"
                            />
                        </FormEntry>
                    </Form>
                </PageHeading>
                <Tabs isReadonly={this.state.IsTemplate} isDynamic={true} className="categories-tabs" entityName="Category" name="Categories" externalEvents={this.state.internalEvents} activeTabId={`${this.state.activeCategoryId}_tab`} >
                    {
                        tabs
                    }
                </Tabs>
                {!this.state.IsTemplate ?
                    <div className="d-page-actions-panel">
                        <PrimaryButton value={`${this.state.isEdit ? "Save" : "Create"}`} click={() => {
                            this.onSubmit()
                        }} />
                    </div>
                    :
                    <></>
                }
            </>
        );
    }
}

export default QuestionnaireDetails;
