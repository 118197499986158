import React, { Component } from "react";
import { Form } from "../form/Form";
import FormEntry from "../form/FormEntry";
import InputComponent from "../components/InputComponent";
import AxiosHelper from "../utils/AxiosHelper";
import { ValidResult } from "../utils/Utils";
import {
  DEFAULT_USER_PICTURE_URL,
  DEFAULT_USER_PICTURE_URL_ABSOLUTE,
} from "../AppConstants";
import PrimaryButton from "./buttons/PrimaryButton";
import { SITE_EVENTS } from "../App";

class ProfilePictureComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value
        ? this.props.value
        : DEFAULT_USER_PICTURE_URL_ABSOLUTE(),
      fileAsUrl: null,
      file: null,
    };

    if (this.props.collectDataEvent) {
      this.props.collectDataEvent.subscribe(this.collectData, this.props.name);
    }

    if (this.props.validateEvent) {
      this.props.validateEvent.subscribe(
        this.validateCallback,
        this.props.name
      );
    }
  }

  collectData = (formData) => {
    if (this.state.file) {
      formData[`${this.props.name}File`] = this.state.file;
    } else {
      formData[`${this.props.name}File`] = null;
    }
  };

  handleChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file,
        fileAsUrl: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  validateCallback = () => {
    return true;
  };

  render() {
    const pickerRef = React.createRef(null);

    return (
      <div className="s-profile-picture">
        <div
          className="camera-div"
          onClick={() => {
            pickerRef.current.click();
          }}
        ></div>
        <img
          src={this.state.file ? this.state.fileAsUrl : this.state.value}
          className="my-profile-photo"
        />
        <input type="file" ref={pickerRef} onChange={this.handleChange} />
      </div>
    );
  }
}

class SurgeonProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      FirstName: null,
      LastName: null,
      Email: null,
      Phone: null,
      Website: null,
      Address: null,
      ProfilePictureUrl: null,
    };
  }

  getData = () => {
    AxiosHelper.post("SurgeonProfile/ProfileSurgeonData")
      .then((response) => {
        if (ValidResult(response.data)) {
          this.setState({
            ...this.state,
            ...response.data.Data,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  componentDidMount(props) {
    this.getData();
  }

  onSubmit = (result) => {
    if (ValidResult(result)) {
      this.props.toastr.ShowSuccess("Successfully updated your profile data.");

      SITE_EVENTS.riseEvent({
        type: "SET_PROFILE_PICTURE_URL",
        payload: result.Data,
      });
    } else {
      this.props.toastr.ShowError("Failed to update your profile data.");
    }
  };

  render() {
    return (
      <div className="s-profile-container">
        <Form
          name="SurgeonProfileForm"
          align="2-col"
          url="Surgeon/UpdateProfile"
          containsFile={true}
          dontClear={true}
          callback={this.onSubmit}
        >
          <FormEntry
            name="ProfilePicture"
            text="Profile Picture"
            value={this.state.ProfilePictureUrl}
          >
            <ProfilePictureComponent />
          </FormEntry>
          <FormEntry
            name="FirstName"
            text="First Name"
            value={this.state.FirstName}
          >
            <InputComponent />
          </FormEntry>
          <FormEntry
            name="LastName"
            text="Last Name"
            value={this.state.LastName}
          >
            <InputComponent />
          </FormEntry>
          <FormEntry name="Email" text="Email" value={this.state.Email}>
            <InputComponent disabled={true} />
          </FormEntry>
          <FormEntry name="Phone" text="Phone" value={this.state.Phone}>
            <InputComponent />
          </FormEntry>
          <FormEntry name="Website" text="Website" value={this.state.Website}>
            <InputComponent />
          </FormEntry>
          <FormEntry name="Address" text="Address" value={this.state.Address}>
            <InputComponent />
          </FormEntry>
        </Form>
      </div>
    );
  }
}

export default SurgeonProfileForm;
