import React, { Component } from 'react';
import PatientTable from './PatientTable';
import TextFilterEntry from '../filterAndPagination/components/TextFilterEntry';
import FilterHandler from '../filterAndPagination/FilterHandler';
import DateFilterEntry from '../filterAndPagination/components/DateFilterEntry';
import MultiselectTagsFilterEntry from '../filterAndPagination/components/MultiselectTagsFilterEntry';

class PatientTableWithFilter extends Component {

    render() {
        return (
            <>
                <FilterHandler context={this.props.context}>
                    <TextFilterEntry name="Name" friendlyName="Name" />
                    <DateFilterEntry name="Date" friendlyName="Surgery date" />
                    <TextFilterEntry name="Email" friendlyName="Email" />
                    <MultiselectTagsFilterEntry
                        name="Tags"
                        placeholder="Select Tag"
                        url="Tag/GetAll"
                    />
                </FilterHandler>

                <PatientTable
                    {...this.props}
                />
            </>
        );
    }
}

export default PatientTableWithFilter;