import React, { Component } from "react"
import uuid from "uuid"
import { withRouter } from "react-router";
import "../../css/leftMenu.css"

class Menu extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activeId: props.activeId,
            itemsKeyPool: 0
        }

        if (this.props.routerEvents) {
            this.props.routerEvents.subscribe(this.handleRouterEvents, "HANDLER")
        }
    }

    handleRouterEvents = ({ type, payload }) => {
        switch (type) {
            case "push":
                this.props.history.push(payload)
                break;
            default:
                break;
        }
    }

    onMenuItemClick = id => {
        this.setState({ activeId: id })
    }

    render() {
        return (
            <div className="menu">
                <ul>
                    {(this.props.children.length ? this.props.children : [this.props.children]).map((e) => (
                        React.cloneElement(e, { key: uuid(), click: this.onMenuItemClick, activeId: this.state.activeId })
                    ))}
                </ul>
            </div>
        )
    }
}

export default withRouter(Menu)