import React, { Component } from 'react'
import FormEntry from '../../form/FormEntry';
import { Form } from '../../form/Form';
import InputComponent from '../InputComponent';
import SafeSimpleEvent from '../SafeSimpleEvent';
import { Confirm } from "../ConfirmModule"
import QuestionnaireQuestion from './QuestionnaireQuestion';
import uuid from "uuid"

export class QuestionnaireCategory extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEdit: false,
            questionnaireId: '',

            Name: this.props.value.Name,
            Desc: this.props.value.Desc,
            OrderIndex: this.props.value.OrderIndex,
            questions: this.props.value.Questions ? this.props.value.Questions : [],

            internalEvents: SafeSimpleEvent(),
        }

        this.state.internalEvents.subscribe(this.handleEvents, "ME")

    }

    updateCategoryData = (update) => {
        const data = {}
        this.state.internalEvents.riseEvent({
            type: "collect_category_data",
            payload: data
        })

        data.Id = this.props.value.Id
        data.InternalId = this.props.value.InternalId

        if (update) {
            this.props.externalEvents.riseEvent({
                type: `set_category_data_update`,
                payload: data
            })
        }
        else {
            this.props.externalEvents.riseEvent({
                type: `set_category_data`,
                payload: data
            })
        }
    }

    updateInternalState = () => {
        const questions = []
        this.state.internalEvents.riseEvent({
            type: "question_collect_data",
            payload: questions
        })

        this.state.questions = this.state.questions
            .filter(q => questions.find(qq => qq.InternalId === q.InternalId))
            .map(q => {
                const updatedData = questions.find(qq => qq.InternalId === q.InternalId)
                if (updatedData) {
                    return {
                        ...q,
                        ...updatedData
                    }
                }

                return q
            })

        const data = {}
        this.state.internalEvents.riseEvent({
            type: "QuestionnaireCategoryForm_collect_data_no_validation",
            payload: data
        })

        this.state.Name = data.Name
        this.state.Desc = data.Desc
        this.state.OrderIndex = parseInt(data.OrderIndex)
    }

    addEmptyQuestion = () => {
        this.updateInternalState()

        this.state.questions.push({
            InternalId: uuid(),
            Id: null,
            Title: "",
            QuestionChoices: []
        })

        this.forceUpdate()
    }

    componentDidUpdate = () => {
    }

    handleEvents = ({ type, payload }) => {
        switch (type) {
            case "collect_category_data":
                const data = {}
                const questions = []
                let isValid = true

                var result = this.state.internalEvents.riseEventCheckSpecificResult({
                    type: "QuestionnaireCategoryForm_collect_data",
                    payload: {}
                }, false)
                if (result) {
                    isValid = false
                }

                this.state.internalEvents.riseEvent({
                    type: "QuestionnaireCategoryForm_collect_data_no_validation",
                    payload: data
                })

                result = this.state.internalEvents.riseEventCheckSpecificResult({
                    type: "question_collect_data",
                    payload: questions
                }, false)
                if (result) {
                    isValid = false
                }

                this.state.questions = this.state.questions
                    .map(q => {
                        const updatedData = questions.find(qq => qq.InternalId === q.InternalId)
                        if (updatedData) {
                            return {
                                ...q,
                                ...updatedData
                            }
                        }

                        return q
                    })

                if (this.state.questions.length === 0) {
                    isValid = false
                }

                payload.InternalId = this.props.value.InternalId
                payload.Id = this.props.value.Id
                payload.Name = data.Name
                payload.Desc = data.Desc
                payload.OrderIndex = parseInt(data.OrderIndex)
                payload.Questions = [...this.state.questions]

                this.state.Name = data.Name
                this.state.Desc = data.Desc
                this.state.OrderIndex = payload.OrderIndex

                return isValid
            case "remove_question": {
                this.state.internalEvents.riseUnsubscribeEvent({
                    type: "on_will_destroy_question",
                    payload
                })

                this.props.externalEvents.riseEvent({
                    type: "on_question_removed"
                })

                this.setState(prevState => ({
                    questions: prevState.questions.filter(q => q.InternalId !== payload)
                }))
            } break;
            case "get_active_category_id": {
                return this.props.value.InternalId
            } break
            case "category_update": {
                this.forceUpdate()

            } break
            default:
                break;
        }
    }

    componentDidMount = () => {
        this.props.externalEvents.subscribe(this.handleEvents, `Questionnaire_${this.props.value.InternalId}`)
    }

    componentWillUnmount = () => {
        this.props.externalEvents.riseEvent({
            type: "set_active_category_id",
            payload: this.props.value.InternalId
        })
        this.props.externalEvents.unsubscribe(`Questionnaire_${this.props.value.InternalId}`)
    }

    deleteThis = () => {
        this.props.externalEvents.riseEvent({
            type: "delete_questionnaire_category",
            payload: this.props.value.InternalId
        })
    }

    handleConfirm = ({ type, payload }) => {
        const { subType, obj } = payload

        if (type === "ok") {
            if (subType === "delete") {
                this.deleteThis()
            }
        }
    }

    render() {
        return (
            <div className="s-category">
                <div className="s-category-form-container">
                    <Form
                        isReadonly={this.props.isReadonly }
                        id={`QuestionnaireCategoryForm_${this.props.value.InternalId}`}
                        name="QuestionnaireCategoryForm"
                        externalEvents={this.state.internalEvents}
                        collectIfNotValid={true}
                        align="inline">
                        <FormEntry text="Category*" name="Name" value={this.state.Name} placeholder="Name">
                            <InputComponent />
                        </FormEntry>
                        <FormEntry text="Description*" name="Desc" value={this.state.Desc}>
                            <InputComponent />
                        </FormEntry>
                        <FormEntry text="Order Number*" name="OrderIndex" value={this.state.OrderIndex}>
                            <InputComponent />
                        </FormEntry>
                    </Form>
                    {!this.props.isReadonly ?
                        <div className="s-category-panel">
                            <label onClick={this.addEmptyQuestion}><span></span>Add Question</label>
                            <label onClick={() => {
                                Confirm.Show(
                                    `Confirm`,
                                    `Are you sure you want to delete this questionnaire category?`,
                                    this.handleConfirm,
                                    { subType: "delete" }
                                )
                            }} ><span></span>Delete Categoy</label>
                        </div>
                        : <></>
                    }
                </div>
                <div className="s-category-questions">
                    {
                        this.state.questions.map((q, i) => (
                            <QuestionnaireQuestion value={q} key={q.InternalId} externalEvents={this.state.internalEvents} />
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default QuestionnaireCategory
