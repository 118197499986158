const SiteState = {
  LoggedIn: "LOGGED_IN",
  LoggedOut: "LOGGED_OUT",
  Loading: "LOADING",
  ChooseHospital: "CHOOSE_HOSPITAL",
  AfterChooseHospital: "AFTER_CHOOSE_HOSPITAL",
  RessetPasswordPage: "RESSET_PASSWORD",
  ForgotPassword: "FORGOT_PASSWORD",
  ChooseSurgeon: "CHOOSE_SURGEON",
  AfterChooseSurgeon: "After_CHOOSE_SURGEON",
};

export default SiteState;
