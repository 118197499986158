import React, { Component, useReducer, useContext } from 'react';
import PathwayTable from '../components/table/PathwayTable';
import SafeSimpleEvent from '../components/SafeSimpleEvent';
import SavePathwayDialog from '../components/SavePathwayDialog';
import PageHeading, { PageTitle, ActionGroup } from '../components/PageHeading';
import PrimaryButton from "../components/buttons/PrimaryButton"

import "../css/base/basicTable.css"
import EventProxy from '../components/EventProxy';
import { Notifications } from '../components/NotificationsComponent';
function _reducer(state, { type, payload }) {
    return state
}
function _PathwaysPage(props) {
    const [state, dispatch] = useReducer(_reducer, {
        savePathwayProxy: EventProxy()
    })

    const toastr = useContext(Notifications)

    props.didMountEvent.subscribe(() => {

    })

    return (
        <>
            <PageHeading >
                <PageTitle text="Pathways" />
                <ActionGroup>
                    <PrimaryButton click={() => { state.savePathwayProxy.riseEvent({ type: "show" }) }} value="Create Pathway" />
                </ActionGroup>
            </PageHeading>
            <PathwayTable toastr={toastr} history={props.history} />
            <SavePathwayDialog savePathwayProxy={state.savePathwayProxy} history={props.history} />
        </>
    )
}

class PathwaysPage extends Component {

    constructor(props) {
        super(props)

        this.didMountEvent = SafeSimpleEvent()
    }

    componentDidMount = () => {
        this.didMountEvent.riseEvent()
    }

    render() {
        return (<_PathwaysPage {...this.props} didMountEvent={this.didMountEvent} />);
    }
}

export default PathwaysPage;
