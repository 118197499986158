import React, { Component } from 'react'
import SafeSimpleEvent from '../SafeSimpleEvent';
import { QuestionOption } from './QuestionOption';
import uuid from "uuid"
import { Form } from '../../form/Form';
import FormEntry from '../../form/FormEntry';
import TextAreaComponent from '../TextAreaComponent';
import { Confirm } from '../ConfirmModule';

export class QuestionnaireQuestion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isEdit: false,

            internalEvents: SafeSimpleEvent(),
            IsTemplate: this.props.value.IsTemplate,
            Title: this.props.value.Title,
            options: this.props.value.QuestionChoices
        }

        this.state.internalEvents.subscribe(this.handleInternalEvents, "ME")
        this.props.externalEvents.subscribe(this.handleEvents, `${this.props.value.InternalId}_question`)
    }

    addNewOption = () => {
        this.updateInternalState()
        this.setState(prevState => ({
            options: [...prevState.options, {
                InternalId: uuid(),
                Id: null,
                Title: "",
                Weight: 1.0,
                Message: ""
            }]
        }))
    }

    updateInternalState = () => {
        const data = {}
        const options = []

        this.state.internalEvents.riseEvent({
            type: "QuestionnaireQuestionForm_collect_data_no_validation",
            payload: data
        })

        this.state.internalEvents.riseEvent({
            type: "option_collect_data",
            payload: options
        })

        this.state.options = this.state.options.map(option => {
            const updatedData = options.find(newOption => newOption.InternalId === option.InternalId)
            if (updatedData) {
                return {
                    ...option,
                    ...updatedData
                }
            }

            return option
        })

        this.state.Title = data.Title
    }

    handleEvents = ({ type, payload }) => {
        switch (type) {
            case "question_collect_data": {
                const data = {}
                const options = []
                let isValid = true

                var result = this.state.internalEvents.riseEventCheckSpecificResult({
                    type: "QuestionnaireQuestionForm_collect_data",
                    payload: {}
                }, false)
                if (result) {
                    isValid = false
                }

                this.state.internalEvents.riseEvent({
                    type: "QuestionnaireQuestionForm_collect_data_no_validation",
                    payload: data
                })

                result = this.state.internalEvents.riseEventCheckSpecificResult({
                    type: "option_collect_data",
                    payload: options
                }, false)
                if (result) {
                    isValid = false
                }

                this.state.options = this.state.options.map(option => {
                    const updatedData = options.find(newOption => newOption.InternalId === option.InternalId)
                    if (updatedData) {
                        return {
                            ...option,
                            ...updatedData
                        }
                    }

                    return option
                })

                if (this.state.options.length === 0) {
                    isValid = false
                }

                const questionData = {
                    InternalId: this.props.value.InternalId,
                    Id: this.props.value.Id,
                    Title: data.Title,
                    QuestionChoices: this.state.options
                }

                this.state.Title = data.Title

                payload.push(questionData)

                return isValid
            }
            case "on_will_destroy_question": {
                if (payload && payload !== this.props.value.InternalId) {
                    return false
                }

                this.state.internalEvents.riseUnsubscribeEvent({
                    type: "on_will_destroy_option"
                })

                this.state.internalEvents.clear()

                return true
            }
            default:
                break;
        }
    }

    handleInternalEvents = ({ type, payload }) => {
        switch (type) {
            case "remove_option": {
                this.state.options = this.state.options.filter(o => o.InternalId !== payload)

                this.state.internalEvents.riseUnsubscribeEvent({
                    type: "on_will_destroy_option",
                    payload
                })

                this.updateInternalState()

                this.forceUpdate()
            } break
            default:
                break;
        }
    }

    componentDidUpdate() {

    }

    handleConfirm = ({ type, payload }) => {
        if (type !== "ok") {
            return
        }

        const { subType, obj } = payload

        if (subType === "remove_question") {
            this.props.externalEvents.riseEvent({
                type: "remove_question",
                payload: this.props.value.InternalId
            })
        }
    }

    render() {
        return (
            <div className="s-questionnaire-question">
                <div className="s-question-head">
                    <Form
                        isReadonly={this.state.IsTemplate}
                        id={`QuestionnaireQuestionForm_${this.props.value.InternalId}`}
                        name="QuestionnaireQuestionForm"
                        collectIfNotValid={true}
                        externalEvents={this.state.internalEvents}
                        align="custom">
                        <FormEntry text="Question*" name="Title" value={this.state.Title} placeholder="Title">
                            <TextAreaComponent />
                        </FormEntry>
                    </Form>
                    {!this.state.IsTemplate ?
                        <label className="s-question-remove" title="Remove Question" onClick={
                            () => {
                                const questionTitle = this.state.internalEvents.riseEventGetFirstDefinedResult({
                                    type: "QuestionnaireQuestionForm_collect_by_name",
                                    payload: "Title"
                                })

                                Confirm.Show(
                                    "Confirm",
                                    `Are you sure you want to delete question "${questionTitle}"?`,
                                    this.handleConfirm,
                                    { subType: "remove_question" })
                            }
                        }></label> :
                        <></>
                    }
                </div>
                <div className="s-question-options">
                    {
                        this.state.options.map((q, i) => (
                            <QuestionOption value={q} index={i} key={q.InternalId} externalEvents={this.state.internalEvents} />
                        ))
                    }
                </div>
                {!this.state.IsTemplate ?
                    <div className="s-question-panel">
                        <label onClick={() => {
                            this.addNewOption()
                        }}>+ New Option</label>
                    </div>
                    :
                    <></>
                }
            </div >
        )
    }
}

export default QuestionnaireQuestion
