import React, { Component } from 'react'

import { SiteContext } from "./contexts"

import "../css/translationSwitch.css"

export class TranslationSwitch extends Component {
  render() {
    return (
      <SiteContext.Consumer>
        {
          ({ state, dispatch }) => (
            <div className='d-site-translation-switch'>
              {/* <label>{t("ChooseLanguageLabel")}</label> */}
              <select onChange={
                (e) => dispatch({ type: "SET_TRANSLATION", payload: { name: e.target.value } })
              } defaultValue={state.activeTranslation.locale}>
                {
                  state.translations.all.map((t, i) => (
                    <option key={t.locale} value={t.locale}>{t.name}</option>
                  ))
                }
              </select>
            </div>
          )
        }
      </SiteContext.Consumer>
    )
  }
}

export default TranslationSwitch
